import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, InputLabel, Select, Button, Grid, Typography } from '@mui/material';
import PrimaryButton from '../../../../Global/PrimaryButton';

const CountrySelection = ({ handleCountrySelect }) => {
  const { t } = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
    setErrorMessage(''); // Reset error message when country changes
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedCountry === 'uk') {
      handleCountrySelect(selectedCountry);
    } else {
      setErrorMessage(t('countrySelection.pitStopNotAvailable'));
    }
  };

  const isCountrySelected = selectedCountry !== '';

  return (
    <Grid container spacing={2} justifyContent='center' sx={{ mt: '5vh' }}>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <FormControl variant='outlined' fullWidth>
            <InputLabel htmlFor='country'>{t('countrySelection.selectCountry')}</InputLabel>
            <Select
              native
              value={selectedCountry}
              onChange={handleCountryChange}
              label={t('countrySelection.selectCountry')}
              inputProps={{
                name: 'country',
                id: 'country'
              }}
            >
              <option aria-label='None' value='' />
              <option value='uk'>{t('countrySelection.unitedKingdom')}</option>
              <option value='other'>{t('countrySelection.other')}</option>
            </Select>
          </FormControl>
          {errorMessage && (
            <Typography variant='body2' color='error' gutterBottom>
              {errorMessage}
            </Typography>
          )}
          <PrimaryButton
            type='submit'
            text={t('countrySelection.continue')}
            sx={{ width: '100%', mt: '2vh' }}
            disabled={!isCountrySelected}
          />
        </form>
      </Grid>
    </Grid>
  );
};

export default CountrySelection;
