import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, TextField, List, InputAdornment, IconButton, MenuItem, Select, Container } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import RecapVehicleCard from './RecapVehicleCard';
import { useTranslation } from 'react-i18next';

const LargeVehicleCountLayout = () => {
  const { t } = useTranslation();

  const vehicleTypes = [
    { value: 'Car', label: t('largeVehicleList.vehicleType.car') },
    { value: 'Truck', label: t('largeVehicleList.vehicleType.truck') },
    { value: 'Motorbike', label: t('largeVehicleList.vehicleType.motorbike') },
    { value: 'Other', label: t('largeVehicleList.vehicleType.other') }
  ];

  const orderByOptions = [
    { value: 'asc', label: t('largeVehicleList.orderByOptions.ascending') },
    { value: 'desc', label: t('largeVehicleList.orderByOptions.descending') }
  ];

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [orderBy, setOrderBy] = useState('');

  const { userVehicles } = useSelector((state) => state.vehicles);

  const filteredVehicles = userVehicles
    .filter((vehicle) => {
      const matchSearchTerm =
        vehicle.make.toLowerCase().includes(searchTerm.toLowerCase()) ||
        vehicle.model.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (vehicle.trim && vehicle.trim.toLowerCase().includes(searchTerm.toLowerCase())) ||
        vehicle.year.includes(searchTerm) ||
        vehicle.identification.toLowerCase().includes(searchTerm.toLowerCase());

      const matchType = selectedType ? vehicle.type === selectedType : true;

      return matchSearchTerm && matchType;
    })
    .sort((a, b) => {
      const yearA = parseInt(a.year, 10);
      const yearB = parseInt(b.year, 10);

      if (orderBy === 'asc') return yearA - yearB;
      if (orderBy === 'desc') return yearB - yearA;
      return 0;
    });

  return (
    <Box>
      <main>
        <Container>
          <TextField
            variant='outlined'
            placeholder={t('largeVehicleList.searchPlaceholder')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => {
                      /* Search functionality could be enhanced here if needed */
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
            fullWidth
            sx={{ mb: 2, mt: 2 }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Select
              value={selectedType} // Ensure this is always a string that matches the value of an option
              onChange={(e) => setSelectedType(e.target.value)}
              displayEmpty
              fullWidth
              sx={{ width: '48%' }}
            >
              <MenuItem value=''>{t('largeVehicleList.filterByType')}</MenuItem>
              {vehicleTypes.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>

            <Select
              value={orderBy}
              onChange={(e) => setOrderBy(e.target.value)}
              displayEmpty
              fullWidth
              sx={{ width: '48%' }}
            >
              <MenuItem value=''>{t('largeVehicleList.orderBy')}</MenuItem>
              {orderByOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Container>

        <Box sx={{ maxHeight: '55vh', overflowY: 'auto' }}>
          <List>
            {filteredVehicles.map((vehicle, index) => (
              <RecapVehicleCard
                key={vehicle.id}
                vehicle={vehicle}
                index={index}
                totalVehicles={filteredVehicles.length}
              />
            ))}
          </List>
        </Box>
      </main>
    </Box>
  );
};

export default LargeVehicleCountLayout;
