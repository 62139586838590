import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Box, Card, CardContent, LinearProgress, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import PrimaryButton from '../../../../Global/PrimaryButton';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const AddressDetails = ({ postAddressLoading, postAddressError, addressDetail, handleAddressSubmit }) => {
  const { t } = useTranslation();

  const getAddressLines = (details) => {
    // Create an array of address components
    const lines = [
      details.line_1,
      details.line_2,
      details.line_3,
      details.line_4,
      `${details.town_or_city ? details.town_or_city + ',' : ''} ${details.postcode}`,
      details.country
    ];
    // Filter out empty strings and join with commas
    return lines.filter((line) => line).join(', ');
  };

  return (
    <Card elevation={3} sx={{ maxWidth: 600, mx: 'auto', mb: 2, overflow: 'hidden' }}>
      <CardContent>
        {postAddressError && (
          <Alert severity='error' sx={{ mb: 2 }}>
            {t('step5Address.errorOccurred')}
          </Alert>
        )}
        {postAddressLoading && <LinearProgress />}

        {addressDetail ? (
          <Box sx={{ p: 2 }}>
            <Typography variant='body1' gutterBottom>
              <LocationOnIcon sx={{ verticalAlign: 'middle', mr: 1 }} />

              {getAddressLines(addressDetail)}
            </Typography>
          </Box>
        ) : (
          <Typography variant='body1' color='textSecondary'>
            {t('step5Address.noAddress')}
          </Typography>
        )}
      </CardContent>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
        <PrimaryButton
          icon={<SendIcon />}
          text={t('step5Address.submit')}
          onClick={() => handleAddressSubmit(addressDetail)}
          disabled={postAddressLoading || !addressDetail}
        />
      </Box>
    </Card>
  );
};

export default AddressDetails;
