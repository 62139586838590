import React from 'react';
import { TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from '@mui/lab';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const AppointmentEventItem = ({ item }) => {
  const { t } = useTranslation();

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot color='secondary' />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Typography variant='h6' color='textPrimary'>
          {t('appointmentEventItem.title')}: {new Date(item.startDate).toLocaleDateString()}
        </Typography>
        <Typography variant='body1' color='textSecondary'>
          {item.notes || t('appointmentEventItem.noAdditionalDetails')}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
};

export default AppointmentEventItem;
