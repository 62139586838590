import { getActiveTransfers, getAllVehiclesFromUser } from '../../api';
import {
  FETCH_VEHICLES_FROM_USER_REQUEST,
  FETCH_VEHICLES_FROM_USER_SUCCESS,
  FETCH_VEHICLES_FROM_USER_FAILURE,
  FETCH_VEHICLE_ACTIVE_TRANSFERS_REQUEST,
  FETCH_VEHICLE_ACTIVE_TRANSFERS_SUCCESS,
  FETCH_VEHICLE_ACTIVE_TRANSFERS_FAILURE
} from '../actionTypes';

export const fetchVehiclesforUser = () => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_VEHICLES_FROM_USER_REQUEST
    });

    const result = await getAllVehiclesFromUser();

    dispatch({
      type: FETCH_VEHICLES_FROM_USER_SUCCESS,
      payload: result
    });
  } catch (error) {
    dispatch({
      type: FETCH_VEHICLES_FROM_USER_FAILURE,
      payload: error
    });
  }
};

export const fetchVehiclesActiveTransfers = () => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_VEHICLE_ACTIVE_TRANSFERS_REQUEST
    });

    const result = await getActiveTransfers();

    dispatch({
      type: FETCH_VEHICLE_ACTIVE_TRANSFERS_SUCCESS,
      payload: result
    });
  } catch (error) {
    dispatch({
      type: FETCH_VEHICLE_ACTIVE_TRANSFERS_FAILURE,
      payload: error
    });
  }
};
