import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchVehiclesforUser } from '../../../redux/Vehicle/actions';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import NoVehicle from './NoVehicle';
import ConfirmButton from '../../Global/PrimaryButton';
import AddIcon from '@mui/icons-material/Add';
import SmallVehicleCountLayout from './SmallVehicleCount/SmallVehicleCountLayout';
import LargeVehicleCountLayout from './LargeVehicleCount/LargeVehicleCountLayout';
import useMediaQuery from '@mui/material/useMediaQuery';
import MobileSmallVehicleCountLayout from './SmallVehicleCount/Mobile/MobileSmallVehicleCountLayout';
import VehicleInfoCardSkeleton from '../SingleVehiclePage/VehicleInfo/VehicleInfoCardSkeleton';
import ErrorFallback from '../../Global/ErrorFallback';

const VehicleListPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const { userVehicles, userVehiclesLoading, userVehiclesError } = useSelector((state) => state.vehicles);

  useEffect(() => {
    dispatch(fetchVehiclesforUser());
  }, [dispatch]);

  const handleAddVehicle = () => {
    navigate('/vehicles/add');
  };

  if (userVehiclesLoading) {
    return (
      <Box>
        <main>
          <Box sx={{ mt: '4vh' }}>
            <VehicleInfoCardSkeleton />
            <VehicleInfoCardSkeleton />
          </Box>
        </main>
      </Box>
    );
  }
  if (userVehiclesError) {
    return (
      <Box display='flex' alignItems='center' justifyContent='center' height='70vh'>
        <ErrorFallback message={t('userInfo.errorFallback')} />
      </Box>
    );
  }

  if (userVehicles.length === 0) {
    return <NoVehicle />;
  }

  const renderVehicleLayout = () => {
    if (userVehicles.length <= 4) {
      return isSmallScreen ? (
        <MobileSmallVehicleCountLayout vehicles={userVehicles} />
      ) : (
        <SmallVehicleCountLayout vehicles={userVehicles} />
      );
    } else {
      return <LargeVehicleCountLayout vehicles={userVehicles} />;
    }
  };

  return (
    <Box>
      <main>{renderVehicleLayout()}</main>
    </Box>
  );
};

export default VehicleListPage;
