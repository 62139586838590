import React, { useState } from 'react';
import { Box, Card, CardContent, CardMedia, Dialog, IconButton, Tooltip, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { confirmVehicleImageUpload, getVehicleImagePresignedUrl, uploadFileToPresignedUrl } from '../../../../../api';
import RegistrationPlate from '../../../../Global/RegistrationPlate';
import GlobalDetails from '../../../Global/GlobalDetails';
import PrimaryButton from '../../../../Global/PrimaryButton';

const CustomImageCheck = ({ open, onGoBack, croppedImage, vehicleSpecificInformation, vehicleUid }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [imageUploading, setImageUploading] = useState(false);
  const [imageUploadingError, setImageUploadingError] = useState(false);

  const base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mimeType });
  };

  const handleImageUpload = async () => {
    if (!croppedImage) return;
    const fileBlob = base64ToBlob(croppedImage.imageUrl, croppedImage.type);
    setImageUploading(true);

    try {
      const response = await getVehicleImagePresignedUrl(croppedImage.name, croppedImage.type, vehicleUid);
      await uploadFileToPresignedUrl(fileBlob, response.presignedUrl, croppedImage.type);
      await confirmVehicleImageUpload(
        {
          name: croppedImage.name,
          fileType: croppedImage.type
        },
        vehicleUid
      );
      setImageUploading(false);
      window.location.reload();
    } catch (error) {
      console.error('Upload failed:', error);
      setImageUploading(false);
      setImageUploadingError(true);
    }
  };

  return (
    <Dialog open={open} maxWidth='md' fullWidth>
      <Card
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '45vh',
          m: 2,
          boxShadow: 3
        }}
      >
        <CardMedia
          component='img'
          src={croppedImage.imageUrl}
          alt='Vehicle'
          onError={(e) => (e.target.src = '/pitstop.png')}
          sx={{
            objectFit: 'contain',
            width: '80vw',
            height: '25vh',
            maxWidth: isSmallScreen ? '90vw' : '30vw',
            maxHeight: isSmallScreen ? '25vh' : '30vh',
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block'
          }}
        />

        <Tooltip title='Go back to previous step' enterDelay={500} leaveDelay={200}>
          <IconButton
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: 'error.main',
              backgroundColor: 'white',
              '&:hover': { backgroundColor: 'grey.100' }
            }}
            onClick={onGoBack} // Ensure onCancel is set to revert to the previous step instead of closing
          >
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>

        <CardContent
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <RegistrationPlate identification={vehicleSpecificInformation.vrm} />
          </Box>

          <Box sx={{ height: '2vh', display: 'flex' }} />

          <GlobalDetails
            type={vehicleSpecificInformation.vehicleClass}
            yearOfManufacture={vehicleSpecificInformation.yearOfManufacture}
            color={vehicleSpecificInformation.colour}
            fuelType={vehicleSpecificInformation.fuelType}
          />
        </CardContent>
      </Card>
      <PrimaryButton
        text='Upload New Image'
        onClick={handleImageUpload}
        disabled={imageUploading}
        icon={imageUploading ? <CircularProgress size={24} /> : null}
      />
    </Dialog>
  );
};

export default CustomImageCheck;
