import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { postUpdatePassword } from '../../../../api';
import { useNavigate, useParams } from 'react-router-dom';
import PrimaryButton from '../../../Global/PrimaryButton';
import './PasswordResetForm.css';

const PasswordResetForm = () => {
  const { t } = useTranslation();
  const { resetToken } = useParams();
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    if (newPassword !== confirmPassword) {
      setMessage(t('passwordResetForm.passwordsDoNotMatch'));
      return;
    }

    setLoading(true);
    try {
      await postUpdatePassword(resetToken, newPassword);
      setMessage(t('passwordResetForm.successMessage'));
      setTimeout(() => {
        navigate('/login');
      }, 1500);
    } catch (error) {
      if (error.response?.status === 400) {
        setMessage(t('passwordResetForm.errorMessage'));
      }

      setMessage(t('passwordResetForm.errorMessage'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='password-reset-form-container'>
      <form onSubmit={handleSubmit} className='password-reset-form'>
        <h2>{t('passwordResetForm.title')}</h2>
        <TextField
          label={t('passwordResetForm.newPasswordLabel')}
          variant='outlined'
          type='password'
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          fullWidth
          required
          sx={{ mb: 2 }}
        />
        <TextField
          label={t('passwordResetForm.confirmPasswordLabel')}
          variant='outlined'
          type='password'
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          fullWidth
          required
        />
        <PrimaryButton
          text={loading ? null : t('passwordResetQuery.submitButton')}
          disabled={loading}
          onClick={() => handleSubmit()}
          icon={loading ? <CircularProgress size={24} /> : null}
          sx={{ mt: 3, mb: 2 }}
        />
        {message && <div className='password-reset-message'>{message}</div>}
      </form>
    </div>
  );
};

export default PasswordResetForm;
