import React, { useState } from 'react';
import { Grid } from '@mui/material';
import AddressSearchForm from '../../Global/AddressSearchForm';
import { getAddressDetails, getAddresses } from '../../../api';

const AddressInfo = ({ setAddressDetail }) => {
  const [addressOptions, setAddressOptions] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleAddressChange = async (e) => {
    const inputValue = e.target.value;
    setSelectedAddress(inputValue);

    if (inputValue.length > 3) {
      const data = await getAddresses(inputValue);

      setAddressOptions(data);
    } else {
      setAddressOptions([]);
    }
  };

  const handleAddressOptionClick = async (selected) => {
    setSelectedAddress(selected.address);
    const data = await getAddressDetails(selected.id);
    setAddressDetail(data);
    setIsDropdownOpen(false);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <Grid item xs={12}>
      <AddressSearchForm
        inputValue={selectedAddress}
        onChange={handleAddressChange}
        onFocus={handleDropdownToggle}
        addressOptions={addressOptions}
        handleAddressOptionClick={handleAddressOptionClick}
      />
    </Grid>
  );
};

export default AddressInfo;
