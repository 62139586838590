import React, { useState, useCallback, memo } from 'react';
import { Box, CircularProgress, Modal, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { revokePermission } from '../../../../api';
import ErrorFallback from '../../../Global/ErrorFallback';
import PrimaryButton from '../../../Global/PrimaryButton';
import SecondaryButton from '../../../Global/SecondaryButton';

const ModalBox = ({ children }) => (
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      p: 4
    }}
  >
    {children}
  </Box>
);

const RevokePermissionModal = ({ open, onClose, permissionId }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const handleRevoke = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      await revokePermission(permissionId);
      window.location.reload();
      onClose();
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [permissionId, onClose]);

  return (
    <Modal
      open={open}
      onClose={() => {
        setError(null);
        onClose();
      }}
      disableBackdropClick={!error}
    >
      <ModalBox>
        {error ? (
          <ErrorFallback message={t('userInfo.errorFallback')} />
        ) : (
          <>
            <Typography variant='h6'>{t('readPermission.confirmRevoke')}</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2 }}>
              <PrimaryButton
                text={loading ? null : t('readPermission.yesRevoke')}
                onClick={handleRevoke}
                icon={loading ? <CircularProgress size={24} /> : null}
                disabled={loading}
              />
              <SecondaryButton text={t('readPermission.cancel')} onClick={onClose} disabled={loading} />
            </Box>
          </>
        )}
      </ModalBox>
    </Modal>
  );
};

export default memo(RevokePermissionModal);
