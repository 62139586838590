import React, { useRef, useState } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Button, Card, CardMedia, Dialog, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomDropzone from '../../../../Global/CustomDropzone';
import { useTranslation } from 'react-i18next';

const CustomImageUploadModal = ({ open, onClose, onNext }) => {
  const [src, setSrc] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [fileName, setFileName] = useState('');
  const [fileType, setFileType] = useState('');
  const cropperRef = useRef(null);
  const { t } = useTranslation();

  const handleClose = () => {
    setSrc(null);
    setCroppedImage(null);
    setShowCropper(false);
    onClose();
  };
  const handleFileAccepted = (file) => {
    setFileName(file.name);
    setFileType(file.type);

    const reader = new FileReader();
    reader.onload = (e) => {
      setSrc(e.target.result);
      setShowCropper(true);
    };
    reader.readAsDataURL(file);
  };

  const onCropConfirm = () => {
    if (cropperRef.current && cropperRef.current.cropper) {
      const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();
      if (croppedCanvas) {
        const croppedImageUrl = croppedCanvas.toDataURL();
        setCroppedImage(croppedImageUrl);
        setShowCropper(false);
        onNext({
          imageUrl: croppedImageUrl,
          name: fileName,
          type: fileType
        });
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth>
      <Card>
        <IconButton onClick={handleClose} style={{ position: 'absolute', right: 0, top: 0 }}>
          <CloseIcon />
        </IconButton>
        {!src && (
          <CustomDropzone
            onFileAccepted={handleFileAccepted}
            acceptedFiles={['image/jpeg', 'image/png', 'application/pdf', 'image/heic', 'image/heif', 'image/jpg']}
          />
        )}
        {showCropper && src && (
          <Cropper
            ref={cropperRef}
            src={src}
            style={{ height: '50vh', width: '100%' }}
            aspectRatio={16 / 9}
            // guides={false}
            cropBoxResizable={false}
            dragMode='move'
            viewMode={1}
          />
        )}
        {croppedImage && !showCropper && (
          <CardMedia
            component='img'
            src={croppedImage}
            alt='Cropped Vehicle'
            sx={{
              objectFit: 'contain',
              width: '80vw',
              height: '25vh',
              marginLeft: 'auto',
              marginRight: 'auto',
              display: 'block'
            }}
          />
        )}
        {showCropper && (
          <Button onClick={onCropConfirm} sx={{ display: 'block', margin: 'auto', mt: 1 }}>
            {t('customImageUploadModal.confirmCrop')}
          </Button>
        )}
      </Card>
    </Dialog>
  );
};

export default CustomImageUploadModal;
