import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography, IconButton } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import Skeleton from 'react-loading-skeleton';
import { fetchVehicleSpecificInformation, fetchVehicleOwnerHistory, fetchVehicleBasicInformation } from '../../../api';
import ErrorFallback from '../../Global/ErrorFallback';
import VehicleDetails from './VehicleDetails';
import MaintenanceQualityScore from './MaintenanceQualityScore/MaintenanceQualityScore';

const VehiclePage = () => {
  const { vehicleUid } = useParams();
  const { t } = useTranslation();

  const [vehicleSpecificInformation, setVehicleSpecificInformation] = useState(null);
  const [vehicleSpecificInformationError, setVehicleSpecificInformationError] = useState(null);
  const [vehicleSpecificInformationLoading, setVehicleSpecificInformationLoading] = useState(true);

  const [vehicleOwnerHistory, setVehicleOwnerHistory] = useState(null);
  const [vehicleOwnerHistoryError, setVehicleOwnerHistoryError] = useState(null);
  const [vehicleOwnerHistoryLoading, setVehicleOwnerHistoryLoading] = useState(true);

  const [vehicleBasicInformation, setVehicleBasicInformation] = useState(null);
  const [vehicleBasicInformationError, setVehicleBasicInformationError] = useState(null);
  const [vehicleBasicInformationLoading, setVehicleBasicInformationLoading] = useState(true);

  useEffect(() => {
    const fetchOwnerHistory = async () => {
      try {
        const vehicleOwnerHistoryResponse = await fetchVehicleOwnerHistory(vehicleUid);
        setVehicleOwnerHistory(vehicleOwnerHistoryResponse);
      } catch (error) {
        setVehicleOwnerHistoryError(true);
      } finally {
        setVehicleOwnerHistoryLoading(false);
      }
    };

    fetchOwnerHistory();
  }, [vehicleUid]);

  useEffect(() => {
    const fetchSpecificInformation = async () => {
      try {
        const vehicleSpecificInformationResponse = await fetchVehicleSpecificInformation(vehicleUid);
        setVehicleSpecificInformation(vehicleSpecificInformationResponse);
      } catch (error) {
        setVehicleSpecificInformationError(true);
      } finally {
        setVehicleSpecificInformationLoading(false);
      }
    };

    fetchSpecificInformation();
  }, [vehicleUid]);

  useEffect(() => {
    const fetchBasicInformation = async () => {
      try {
        const vehicleBasicInformation = await fetchVehicleBasicInformation(vehicleUid);
        setVehicleBasicInformation(vehicleBasicInformation);
      } catch (error) {
        setVehicleBasicInformationError(true);
      } finally {
        setVehicleBasicInformationLoading(false);
      }
    };

    fetchBasicInformation();
  }, [vehicleUid]);

  if (vehicleSpecificInformationError) {
    return <ErrorFallback message={t('vehicleDetails.error')} />;
  }

  if (vehicleSpecificInformationLoading) {
    return <Skeleton variant='rectangular' width={210} height={118} />;
  }

  if (vehicleSpecificInformation) {
    return (
      <Box sx={{ marginTop: '4vh' }}>
        <header>
          <Box display='flex' alignItems='center'>
            <Box display='flex' alignItems='center'>
              <Link to='/'>
                <IconButton>
                  <ArrowBackIcon />
                </IconButton>
              </Link>
              <Typography variant='h4' ml={2}>
                {vehicleSpecificInformation.year} {vehicleSpecificInformation.make} {vehicleSpecificInformation.model}
              </Typography>
              <Typography variant='h4' ml={2} color='textSecondary' style={{ color: 'green' }}>
                {vehicleSpecificInformation.trim}
              </Typography>
            </Box>
          </Box>
        </header>
        {vehicleBasicInformation && vehicleBasicInformation.hasActiveTransfer && (
          <Box sx={{ backgroundColor: '#FFC107', color: 'black', p: 2, textAlign: 'center', mb: 2 }}>
            <Typography variant='subtitle1'>{t('vehicleDetails.activeTransferRequest')}</Typography>
          </Box>
        )}

        <VehicleDetails
          vehicleSpecificInformation={vehicleSpecificInformation}
          vehicleSpecificInformationLoading={vehicleSpecificInformationLoading}
          vehicleSpecificInformationError={vehicleSpecificInformationError}
          vehicleOwnerHistory={vehicleOwnerHistory}
          vehicleOwnerHistoryLoading={vehicleOwnerHistoryLoading}
          vehicleOwnerHistoryError={vehicleOwnerHistoryError}
          withActions={true}
        />
      </Box>
    );
  } else {
    return null;
  }
};

export default VehiclePage;
