import React from 'react';
import Account from './Components/Account/AccountPage';
import InvoiceManagement from './Components/Invoice/List/InvoiceManagement';
import PostCredentialsCheckSignupForm from './Components/AccessControl/Signup/PostCredentialsCheckForm';
import SignupForm from './Components/AccessControl/Signup/SignupForm';
import LoginForm from './Components/AccessControl/Login/LoginForm';
import LogoutForm from './Components/AccessControl/Logout/LogoutForm';
import NotFound from './Components/Global/NotFound';
import { Navigate } from 'react-router-dom';
import VehicleListPage from './Components/Vehicle/List/VehicleListPage';
import AddVehicle from './Components/Vehicle/AddVehicle/AddVehicle';
import TransferVehicleForm from './Components/Vehicle/TransferVehicle/TransferVehicleForm';
import AcceptVehicleTransfer from './Components/Vehicle/TransferVehicle/AcceptVehicleTransfer';
import HistoryDisplay from './Components/Vehicle/HistoryDisplay/HistoryDisplay';
import ShareVehicleHistory from './Components/Invoice/ShareHistory/ShareHistoryPage';
import VehiclePage from './Components/Vehicle/SingleVehiclePage/VehiclePage';
import ConsultSharedHistory from './Components/Invoice/ShareHistory/ConsultSharedHistory';
import PasswordResetQuery from './Components/AccessControl/Login/PasswordReset/PasswordResetQuery';
import PasswordResetForm from './Components/AccessControl/Login/PasswordReset/PasswordResetForm';
import TransfersList from './Components/Vehicle/TransfersList/TransferList';
import DocumentVerification from './Components/Account/DocumentVerification/DocumentVerification';

const protectedRoute = (isLoggedIn, Component) => {
  return isLoggedIn ? Component : <Navigate to='/login' />;
};

const routes = (isLoggedIn) => [
  {
    path: '/',
    children: [
      // Landing
      { path: '/', element: protectedRoute(isLoggedIn, <VehicleListPage />) },

      // Auth
      { path: '/signup-information', element: protectedRoute(isLoggedIn, <PostCredentialsCheckSignupForm />) },
      { path: '/signup', element: !isLoggedIn ? <SignupForm /> : <Navigate to='/' /> },
      { path: '/logout', element: isLoggedIn ? <LogoutForm /> : <Navigate to='/login' /> },
      { path: '/login', element: !isLoggedIn ? <LoginForm /> : <Navigate to='/' /> },
      { path: '/password-reset', element: !isLoggedIn ? <PasswordResetQuery /> : <Navigate to='/' /> },
      { path: '/password-reset/:resetToken', element: !isLoggedIn ? <PasswordResetForm /> : <Navigate to='/' /> },

      //Document Verification
      { path: '/document/verification', element: protectedRoute(isLoggedIn, <DocumentVerification />) },

      //Features
      { path: '/account', element: protectedRoute(isLoggedIn, <Account />) },
      { path: '/invoices', element: protectedRoute(isLoggedIn, <InvoiceManagement />) },
      { path: '/transfers', element: protectedRoute(isLoggedIn, <TransfersList />) },
      { path: '/vehicles/add', element: protectedRoute(isLoggedIn, <AddVehicle />) },
      { path: '/vehicles/:vehicleUid', element: protectedRoute(isLoggedIn, <VehiclePage />) },
      { path: '/vehicles/:vehicleUid/receive', element: protectedRoute(isLoggedIn, <AcceptVehicleTransfer />) },
      { path: '/vehicles/:vehicleUid/transfer', element: protectedRoute(isLoggedIn, <TransferVehicleForm />) },
      {
        path: '/vehicles/:vehicleUid/history/:permissionUid',
        element: protectedRoute(isLoggedIn, <HistoryDisplay />)
      },
      { path: '/vehicles/:vehicleUid/history/share', element: protectedRoute(isLoggedIn, <ShareVehicleHistory />) },
      {
        path: '/vehicles/:vehicleUid/shared-history/:permissionUid',
        element: protectedRoute(isLoggedIn, <ConsultSharedHistory />)
      },

      // Default
      { path: '*', element: <NotFound /> }
    ]
  }
];

export default routes;
