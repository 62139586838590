import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const AddressSearchForm = ({
  inputValue,
  onChange,
  onFocus,
  addressOptions,
  handleAddressOptionClick,
  handleGoBackPreviousStep
}) => {
  const { t } = useTranslation();
  const [showOptions, setShowOptions] = useState(true);

  const handleClickOption = (option) => {
    handleAddressOptionClick(option);
    setShowOptions(false);
  };

  const handleFocus = () => {
    onFocus();
    setShowOptions(true);
  };

  return (
    <div className='form-container' style={{ mt: '5vh', paddingTop: '10vh', position: 'relative' }}>
      <input
        type='text'
        className='form-control'
        id='address'
        placeholder={t('addressSearchForm.placeholder')}
        value={inputValue}
        onChange={onChange}
        onFocus={handleFocus}
        style={{ width: '100%' }}
      />
      {showOptions && addressOptions && addressOptions.length > 0 && (
        <div
          className='address-options'
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            width: '100%',
            maxHeight: '50vh',
            overflowY: 'auto',
            background: '#fff',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            zIndex: 1000,
            borderRadius: '4px'
          }}
        >
          {addressOptions.map((option) => (
            <div
              key={option.id}
              onClick={() => handleClickOption(option)}
              className='address-option'
              style={{
                padding: '10px 15px',
                cursor: 'pointer',
                borderBottom: '1px solid #eee'
              }}
            >
              <span style={{ display: 'block', fontWeight: 'bold' }}>{option.name}</span>
              <span style={{ display: 'block', color: '#666' }}>{option.address}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AddressSearchForm;
