import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { onboardingStatusRequest } from '../../../redux/Registration/onboardingStatusActions';
import Step1Credentials from './SignupSteps/Step1Credentials/Step1Credentials';
import Step2EmailConfirmation from './SignupSteps/Step2EmailConfirmation/Step2EmailConfirmation';
import './SignupForm.css';

const SignupForm = () => {
  const [step, setStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const [emailAddress, setEmailAddress] = useState('');

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  if (isLoggedIn) {
    window.location.href = '/';
  }

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const uid = queryParams.get('id');

  const dispatch = useDispatch();
  const { onboardingStatus, onboardingStatusLoading } = useSelector((state) => state.onboardingStatus);

  useEffect(() => {
    if (uid) {
      dispatch(onboardingStatusRequest(uid));
    }
  }, [uid]);

  useEffect(() => {
    if (onboardingStatus) {
      if (!onboardingStatus.emailConfirmed) {
        setStep(2);
      } else if (!onboardingStatus.employeeInformation) {
        setStep(3);
      } else if (!onboardingStatus.garageInformation) {
        setStep(4);
      }
    }
  }, [onboardingStatus]);

  const handleCredentialsSubmit = () => {
    setStep(2);
  };

  if (onboardingStatusLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          height: '80vh',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className='signup-form-container'>
      {step === 1 && (
        <Step1Credentials
          errorMessage={errorMessage}
          handleCredentialsSubmit={handleCredentialsSubmit}
          setEmailAddress={setEmailAddress}
        />
      )}
      {step === 2 && <Step2EmailConfirmation errorMessage={errorMessage} emailAddress={emailAddress} />}
    </div>
  );
};

export default SignupForm;
