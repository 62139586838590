import React from 'react';
import { Grid, TextField, Box, InputAdornment } from '@mui/material';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'; // Ensure you import the default styling.

const ContactInfo = ({ formFields, handleChange, setFormFields }) => {
  return (
    <>
      <Box mb={2}>
        {' '}
        {/* Add margin-bottom to the email address field */}
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            label='Email Address'
            value={formFields.emailAddress}
            onChange={handleChange('emailAddress')}
            variant='outlined'
            type='email'
          />
        </Grid>
      </Box>
      <Grid item xs={12}>
        <Box
          fullWidth
          variant='outlined'
          sx={{ '& .PhoneInput': { width: '100%' }, '& .PhoneInputInput': { width: '100%', padding: '18.5px 14px' } }}
        >
          <PhoneInput
            international
            defaultCountry='GB'
            value={formFields.phoneNumber}
            onChange={(value) => setFormFields({ ...formFields, phoneNumber: value })}
            style={{ width: '100%' }}
          />
        </Box>
      </Grid>
    </>
  );
};

export default ContactInfo;
