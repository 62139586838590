import Cookies from 'js-cookie';
import { TOKEN_KEY, USER_UID_KEY, TIMEZONE_KEY, STATUS_KEY} from './cookieConst';

export const addTokenInCache = (token) => {
  Cookies.set(TOKEN_KEY, token, { expires: 6 }); //6 days
};

export const addOnboardingStatusInCache = (status) => {
  Cookies.set(STATUS_KEY, status);
};

export const addUserInCache = (userUid) => {
  Cookies.set(USER_UID_KEY, userUid);
};

export const addTimezoneInCache = (timezone) => {
  Cookies.set(TIMEZONE_KEY, timezone);
};

export const isFullyOnboarded = () => {
  if (Cookies.get(STATUS_KEY)) {
    return Cookies.get(STATUS_KEY);
  }

  return false;
};

export const isLoggedIn = () => {
  if (Cookies.get(TOKEN_KEY)) {
    return true;
  }

  return false;
};
