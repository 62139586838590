import { Typography, Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Disclaimer = ({ isSmallScreen }) => {
  const { t } = useTranslation();

  const containerStyles = isSmallScreen
    ? {
        marginBottom: '2vh',
        maxWidth: '80vw',
        textAlign: 'center'
      }
    : {
        marginBottom: '2vh'
      };

  return (
    <Box style={containerStyles}>
      <Typography variant='body1'>{t('proofOfOwnershipList.disclaimer')}</Typography>
    </Box>
  );
};

export default Disclaimer;
