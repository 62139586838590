import React from 'react';
import { IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

const Header = ({ title, handleGoBack }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <IconButton onClick={handleGoBack} aria-label='Go back' style={{ marginRight: '8px' }}>
      <ArrowBack />
    </IconButton>
    <h2>{title}</h2>
  </div>
);

export default Header;
