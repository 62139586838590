import React from 'react';
import { Box, Typography } from '@mui/material';
import { MdErrorOutline } from 'react-icons/md';

const ErrorFallback = ({ message, instructions }) => {
  return (
    <div className='error-fallback'>
      <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
        <MdErrorOutline size={80} style={{ color: 'red' }} />
        <Typography variant='h5' align='center' color='red'>
          {message}
        </Typography>
        {instructions && (
          <Typography variant='subtitle1' align='center' color='text.secondary'>
            {instructions}
          </Typography>
        )}
      </Box>
    </div>
  );
};

export default ErrorFallback;
