import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faCar, faFileInvoice, faKey, faLocationPin, faUser } from '@fortawesome/free-solid-svg-icons';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import featureFlags from '../../featureFlags';
import './SideBar.css';

const SideBar = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const menuItems = [
    {
      label: t('menu.yourVehicles'),
      icon: faCar,
      path: '/'
    },
    {
      label: t('menu.invoices'),
      icon: faFileInvoice,
      path: '/invoices'
    },
    {
      label: t('menu.transfers'),
      icon: faKey,
      path: '/transfers'
    },
    {
      label: t('menu.account'),
      icon: faUser,
      path: '/account'
    }
  ];

  if (featureFlags.garageSearch) {
    menuItems.push({
      label: t('menu.garageFinder'),
      icon: faLocationPin,
      path: '/garage-finder'
    });
  }

  return (
    <div className='sidebar'>
      <List component='nav'>
        {menuItems.map((item) => (
          <ListItem
            button
            key={item.label}
            component={Link}
            to={item.path}
            className={`sidebar-link${item.path === location.pathname ? ' active' : ''}`}
          >
            <ListItemIcon className='sidebar-icon'>
              <FontAwesomeIcon icon={item.icon} />
            </ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default SideBar;
