import React, { useState, useEffect } from 'react';
import { Container, Box, CircularProgress, Typography, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PersonalInfo from './PersonalInfo';
import ContactInfo from './ContactInfo';
import AddressInfo from './AddressInfo';
import { getUserClientInformation, putUserClientInformation } from '../../../api';
import ActionButtons from './ActionButtons';

function UpdateClientInfoForm({ onCancel }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [formFields, setFormFields] = useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    selectedAddress: ''
  });

  const [addressDetail, setAddressDetail] = useState(null);

  useEffect(() => {
    const fetchUserClientInformation = async () => {
      setLoading(true);
      try {
        const data = await getUserClientInformation();
        setFormFields({
          firstName: data.firstName || '',
          lastName: data.lastName || '',
          emailAddress: data.emailAddress.trim().toLowerCase() || '',
          phoneNumber: data.phoneNumber || '',
          selectedAddress: data.address || ''
        });
        setAddressDetail(data.addressDetail || null);
      } catch (e) {
        setError(e.toString());
      } finally {
        setLoading(false);
      }
    };
    fetchUserClientInformation();
  }, []);

  const handleChange = (field) => (e) => {
    setFormFields({ ...formFields, [field]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await putUserClientInformation({ ...formFields, addressDetail });
      onCancel();
    } catch (e) {
      setError(e.toString());
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Typography variant='body1' color='error' textAlign='center'>
        {error}
      </Typography>
    );
  }

  return (
    <Container component={Paper} sx={{ p: 4, my: 2, maxWidth: 'md' }}>
      <Typography variant='h6' gutterBottom>
        {t('updateClientInfoForm.title')}
      </Typography>
      <Box component='form' noValidate sx={{ mt: 1 }}>
        <Box mb={2}>
          <PersonalInfo formFields={formFields} handleChange={handleChange} />
        </Box>
        <Box mb={2}>
          <ContactInfo formFields={formFields} handleChange={handleChange} setFormFields={setFormFields} />
        </Box>
        <Box mb={2}>
          <AddressInfo formFields={formFields} setFormFields={setFormFields} setAddressDetail={setAddressDetail} />
        </Box>
        <Box mb={2}>
          <ActionButtons onCancel={onCancel} onSubmit={handleSubmit} />
        </Box>
        {error && (
          <Typography variant='body2' color='error' textAlign='center' sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
      </Box>
    </Container>
  );
}

export default UpdateClientInfoForm;
