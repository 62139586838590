import React, { useState } from 'react';
import { Alert, Box, TextField, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import PrimaryButton from '../Global/PrimaryButton';
import { useTranslation } from 'react-i18next';
import { postCustomerSupportMessage } from '../../api';

const ContactSupportForm = () => {
  const { t } = useTranslation();

  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      const data = {
        subject,
        message
      };

      await postCustomerSupportMessage(data);

      setSuccess(t('contactSupportForm.successMessage'));
      setSubject('');
      setMessage('');
    } catch (error) {
      setError(t('contactSupportForm.errorMessage'));
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 500,
        mx: 'auto',
        p: 4,
        mt: 10,
        boxShadow: 3,
        borderRadius: 2,
        bgcolor: 'background.paper'
      }}
    >
      <Typography variant='h6' sx={{ mb: 2 }}>
        {t('contactSupportForm.contactSupport')}
      </Typography>
      {error && (
        <Alert severity='error' sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      {success && (
        <Alert severity='success' sx={{ mb: 2 }}>
          {success}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <TextField
          label={t('contactSupportForm.subject')}
          type='text'
          fullWidth
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          sx={{ mb: 2 }}
          required
          inputProps={{ maxLength: 100 }}
        />
        <TextField
          label={t('contactSupportForm.message')}
          type='text'
          multiline
          rows={4}
          fullWidth
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          sx={{ mb: 2 }}
          required
          inputProps={{ maxLength: 2000 }}
        />
        <PrimaryButton
          text={t('contactSupportForm.sendMessage')}
          sx={{ width: '100%' }}
          type='submit'
          icon={<SendIcon />}
        />
      </form>
    </Box>
  );
};

export default ContactSupportForm;
