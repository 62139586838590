import React, { useState } from 'react';
import { Button, Typography, Box, Paper, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteAccountConfirmationModal from './DeleteAccountConfirmationModal';

function DeleteAccountForm() {
  const { t } = useTranslation();
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const handleConfirmation = () => {
    setConfirmationDialogOpen(true);
  };

  return (
    <Container maxWidth='sm'>
      <Paper sx={{ p: 4, mt: 3, mb: 3, bgcolor: 'background.paper' }}>
        {/* Delete Account Section */}
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Typography color='error' variant='body2' gutterBottom>
            {t('deleteAccountForm.warningMessage')}
          </Typography>
          <Button variant='contained' color='error' onClick={handleConfirmation} sx={{ mt: 1 }}>
            {t('deleteAccountForm.deleteAccount')}
          </Button>
        </Box>
      </Paper>
      {/* Render the confirmation modal */}
      <DeleteAccountConfirmationModal open={confirmationDialogOpen} onClose={() => setConfirmationDialogOpen(false)} />
    </Container>
  );
}

export default DeleteAccountForm;
