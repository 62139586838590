import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardContent, Chip, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material';
import RegistrationPlate from '../../../Global/RegistrationPlate';
import { fetchVehicleImage } from '../../../../api';
import PrimaryButton from '../../../Global/PrimaryButton';
import RevokePermissionModal from './RevokePermissionModal';

const ReadPermissionCard = ({ permission }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [openModal, setOpenModal] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (!permission.vehicle.identification) return;
    (async () => {
      try {
        const imageDataBase64 = await fetchVehicleImage(permission.vehicle.identification);
        setImageData(imageDataBase64);
        setImageLoaded(true);
      } catch (error) {
        setImageLoaded(true);
      }
    })();
  }, [permission.vehicle.identification]);

  const handleRevoke = () => {
    setOpenModal(true);
  };

  const formattedExpiresDate = new Date(permission.expiresAt).toLocaleDateString();

  const capitalizeName = (name) => {
    if (!name) return '';
    return name.charAt(0).toUpperCase() + name.slice(1);
  };

  return (
    <>
      <Card variant='outlined' sx={{ my: 2, padding: isSmallScreen ? '1vh' : '2vh' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              margin: isSmallScreen ? '0' : '0 2vw'
            }}
          >
            {imageLoaded && imageData ? (
              <Box sx={{ width: isSmallScreen ? '100%' : 300, mb: 2 }}>
                <img
                  src={`data:image/jpeg;base64,${imageData}`}
                  alt='Vehicle'
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                />
              </Box>
            ) : (
              <Skeleton variant='rectangular' width={isSmallScreen ? '100%' : 300} height={120} animation='wave' />
            )}

            <RegistrationPlate identification={permission.vehicle.identification} />
          </Box>

          <CardContent
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Typography variant='h6'>
              {permission.vehicle.make} {permission.vehicle.model}
            </Typography>
            <Typography>{permission.grantee.granteeEmailAddress}</Typography>
            {permission.grantee.id ? (
              `${capitalizeName(permission.grantee.firstName)} ${capitalizeName(permission.grantee.lastName)}`
            ) : (
              <Chip label={t('readPermission.userNoAccount')} color='warning' size='medium' />
            )}

            <Chip
              label={t('readPermission.expires') + formattedExpiresDate}
              sx={{ backgroundColor: '#808080', color: '#fff', mt: 1 }}
              size='small'
            />
          </CardContent>

          <Box sx={{ margin: isSmallScreen ? '2vw' : '5vw', mt: isSmallScreen ? 2 : 0 }}>
            <PrimaryButton text={t('readPermission.revokePermission')} onClick={handleRevoke} sx={{ width: '100%' }} />
          </Box>
        </Box>
      </Card>
      <RevokePermissionModal permissionId={permission.id} open={openModal} onClose={() => setOpenModal(false)} />
    </>
  );
};

export default ReadPermissionCard;
