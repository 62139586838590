import React from 'react';
import { Box, Stepper, Step, StepLabel } from '@mui/material';
import Check from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';

const CustomStepIcon = (props) => {
  const { active, completed, icon } = props;

  return (
    <div
      style={{
        color: '#fff',
        backgroundColor: completed ? 'var(--color-main)' : active ? 'var(--color-secondary)' : '#ccc',
        borderRadius: '50%',
        width: '24px',
        height: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {completed ? <Check /> : icon}
    </div>
  );
};

const StepperComponent = ({ activeStep }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '5vh' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        <Step>
          <StepLabel StepIconComponent={CustomStepIcon}>{t('vehicleAddstepper.vehicleIdentification')}</StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={CustomStepIcon}>{t('vehicleAddstepper.confirmation')}</StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={CustomStepIcon}>{t('vehicleAddstepper.verification')}</StepLabel>
        </Step>
      </Stepper>
    </Box>
  );
};

export default StepperComponent;
