import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import History from '../SingleVehiclePage/History';
import fetchVehicleHistoryFromPermission from '../../../api';

const HistoryDisplay = () => {
  const { t } = useTranslation();
  const { vehicleUid, permissionUid } = useParams();
  const token = useSelector((state) => state.auth.jwt);

  const [vehicleHistory, setVehicleHistory] = useState(null);
  const [vehicleHistoryError, setVehicleHistoryError] = useState(null);
  const [vehicleHistoryLoading, setVehicleHistoryLoading] = useState(null);

  useEffect(() => {
    const fetchVehicleHistory = async (vehicleUid, permissionUid) => {
      setVehicleHistoryLoading(true);
      try {
        const response = await fetchVehicleHistoryFromPermission(vehicleUid, permissionUid);

        setVehicleHistory(response.data);
      } catch (error) {
        setVehicleHistoryError(error);
      }
      setVehicleHistoryLoading(false);
    };
    if (vehicleUid && permissionUid) {
      fetchVehicleHistory(vehicleUid, permissionUid);
    }
  }, [vehicleUid, permissionUid, token]);

  return (
    <Container>
      <Typography variant='h4' sx={{ mt: 4, mb: 2, textAlign: 'center' }}>
        {t('vehicleHistory.title', 'Vehicle History')}
      </Typography>

      <History vehicleUid={vehicleUid} />
    </Container>
  );
};

export default HistoryDisplay;
