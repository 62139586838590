import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getRecentNotifications } from '../api';

const useNotification = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const clientUid = useSelector((state) => state.auth.userUid);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      if (isLoggedIn && clientUid) {
        try {
          const response = await getRecentNotifications();
          setNotifications(response);
        } catch (error) {
          console.error('Failed to fetch notifications');
        }

        // Setup code for Pusher or any other asynchronous setup can also go here
        // Uncomment and use the below template for pusher setup
        /*
                const appKey = process.env.REACT_APP_PUSHER_APP_KEY;
                const cluster = process.env.REACT_APP_PUSHER_APP_CLUSTER;
                const pusher = new Pusher(appKey, {
                  cluster: cluster,
                  forceTLS: true,
                  authEndpoint: `${process.env.REACT_APP_API_URL}/pusher/auth`,
                  auth: {
                    headers: {
                      Authorization: `Bearer ${token}`
                    }
                  }
                });
                const channel = pusher.subscribe('private-' + clientUid);
                channel.bind('new-notification', function (data) {
                  setNotifications(prevNotifications => [...prevNotifications, data]);
                });
                return () => {
                  channel.unbind_all();
                  channel.unsubscribe();
                };
                */
      }
    };

    // Call the async function
    fetchNotifications();
  }, [isLoggedIn, clientUid]);

  return isLoggedIn ? [notifications, setNotifications] : [];
};

export default useNotification;
