import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import Skeleton from 'react-loading-skeleton';
import History from './History';
import VehicleInfoCard from './VehicleInfo/VehicleInfoCard';
import VehicleOwnerHistoryCard from './VehicleOwnerHistoryCard';
import ErrorFallback from '../../Global/ErrorFallback';
import MotInfoCard from './VehicleInfo/MotInfoCard';
import MaintenanceQualityScore from './MaintenanceQualityScore/MaintenanceQualityScore';

const VehicleDetails = ({
  vehicleSpecificInformation,
  vehicleSpecificInformationLoading,
  vehicleSpecificInformationError,
  vehicleOwnerHistory,
  vehicleOwnerHistoryLoading,
  vehicleOwnerHistoryError,
  withActions = true,
  permissionUid = null
}) => {
  const { vehicleUid } = useParams();
  const { t } = useTranslation();
  const [refreshKey, setRefreshKey] = useState(0);

  const handleRefresh = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  if (vehicleSpecificInformationError) {
    return <ErrorFallback message={t('vehicleDetails.error')} />;
  }

  if (vehicleSpecificInformationLoading) {
    return <Skeleton variant='rectangular' width={210} height={118} />;
  }

  if (vehicleSpecificInformation) {
    return (
      <Box sx={{ my: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
            <VehicleInfoCard
              withActions={withActions}
              vehicleSpecificInformation={vehicleSpecificInformation}
              vehicleSpecificInformationLoading={vehicleSpecificInformationLoading}
              vehicleSpecificInformationError={vehicleSpecificInformationError}
              refreshHistory={handleRefresh}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
            <MaintenanceQualityScore />
          </Grid>
          <Grid item xs={12} md={6}>
            <MotInfoCard vehicleUid={vehicleUid} />
          </Grid>
          <Grid item xs={12} md={6}>
            <VehicleOwnerHistoryCard
              vehicleUid={vehicleUid}
              vehicleOwnerHistory={vehicleOwnerHistory}
              vehicleOwnerHistoryLoading={vehicleOwnerHistoryLoading}
              vehicleOwnerHistoryError={vehicleOwnerHistoryError}
            />
          </Grid>
          <Grid item xs={12}>
            <History vehicleUid={vehicleUid} permissionUid={permissionUid} key={refreshKey} />
          </Grid>
        </Grid>
      </Box>
    );
  } else {
    return null;
  }
};

export default VehicleDetails;
