import React from 'react';
import { Typography, Box, Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const OwnershipEventItem = ({ item }) => {
  const { userUid } = useSelector((state) => state.auth);
  const { t } = useTranslation();

  const isCurrentOwner = item.ownerClient.id === userUid;

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const ownerFirstName = capitalize(item.ownerClient.firstName);
  const ownerLastName = capitalize(item.ownerClient.lastName);
  const ownerName = isCurrentOwner ? 'You' : `${ownerFirstName} ${ownerLastName}`;

  return (
    <Box sx={{ mb: 2, p: 2, boxShadow: 'none' }}>
      <Typography variant='h6' color='primary' gutterBottom>
        {t('ownershipEventItem.title')}
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Typography variant='body1' color='textSecondary'>
        {ownerName} {t('ownershipEventItem.acquisitionAction')}.
      </Typography>
      {item.notes && (
        <Typography variant='body1' color='textSecondary' sx={{ mt: 2 }}>
          {t('ownershipEventItem.notes')}:{' '}
          <Typography variant='body1' component='span' color='textPrimary' display='inline'>
            {item.notes}
          </Typography>
        </Typography>
      )}
    </Box>
  );
};

export default OwnershipEventItem;
