import React from 'react';
import { Box } from '@mui/material';
import SecondaryButton from '../../Global/SecondaryButton';
import PrimaryButton from '../../Global/PrimaryButton';
import { useTranslation } from 'react-i18next';

function ActionButtons({ onCancel, onSubmit }) {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 4 }}>
      <SecondaryButton text={t('updateClientInfoForm.cancel')} onClick={onCancel} />
      <PrimaryButton text={t('updateClientInfoForm.saveChanges')} onClick={onSubmit} />
    </Box>
  );
}

export default ActionButtons;
