import axios from 'axios';
import Cookies from 'js-cookie';
import {
  addOnboardingStatusInCache,
  addTimezoneInCache,
  addTokenInCache,
  addUserInCache
} from './utils/index';
import { TOKEN_KEY } from './utils/cookieConst';

const API_URL = process.env.REACT_APP_API_URL;
const token = Cookies.get(TOKEN_KEY);

export const login = async (email, password) => {
  try {
    const response = await axios.post(
      `${API_URL}/login`,
      {
        email: email.trim().toLowerCase(),
        password: password
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    addTokenInCache(response.data.token);
    addTimezoneInCache(response.data.timezone);
    addUserInCache(response.data.userUid);
    addOnboardingStatusInCache(response.data.status);

    return response;
  } catch (error) {
    throw error;
  }
};

export const getUserClientOnboardingStatus = async () => {
  try {
    const response = await axios.get(`${API_URL}/onboarding/onboarding-status`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getClientDocumentStatus = async () => {
  try {
    const response = await axios.get(`${API_URL}/document/status`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postUserClientOnboardingStep4 = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/onboarding/user/step4`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCompanyGovernmentRegistrationInformation = async (companiesHouseRegistrationNumber) => {
  try {
    const response = await axios.get(`${API_URL}/onboarding/company/${companiesHouseRegistrationNumber}`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postOneAppointment = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/appointment`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllVehiclesFromUser = async () => {
  try {
    const response = await axios.get(`${API_URL}/vehicles`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchVehicleOwnerHistory = async (vehicleUid) => {
  try {
    const response = await axios.get(`${API_URL}/vehicles/${vehicleUid}/owner-history`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postVehicleInformation = async (vehicleIdentification) => {
  try {
    const response = await axios.post(
      `${API_URL}/vehicles/new`,
      {
        vehicleIdentification: vehicleIdentification
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return response.data['vehicleId'];
  } catch (error) {
    throw error;
  }
};

export const getAllInvoicesForUserClient = async () => {
  try {
    const response = await axios.get(`${API_URL}/invoices`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getOneinvoiceForUserClient = async (invoiceId) => {
  try {
    const response = await axios.get(`${API_URL}/invoices/${invoiceId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUserClientInformation = async () => {
  try {
    const response = await axios.get(`${API_URL}/account-information`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const putUserClientInformation = async (userClientInformation) => {
  try {
    const response = await axios.put(`${API_URL}/account-information`, userClientInformation, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRecentNotificationForUser = async () => {
  try {
    const response = await axios.get(`${API_URL}/notifications/recent`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateNotificationStatus = async (notificationIds) => {
  try {
    await axios.put(
      `${API_URL}/notifications/read`,
      { notificationIds },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  } catch (error) {
    throw error;
  }
};

export const fetchInvoicePresignedUrl = async (filename, filetype, vehicleUid) => {
  try {
    const response = await axios.get(
      `${API_URL}/invoices/manual-upload/presigned-url?filename=${filename}&filetype=${filetype}&id=${vehicleUid}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getVehicleImagePresignedUrl = async (filename, filetype, vehicleId) => {
  try {
    const response = await axios.get(
      `${API_URL}/vehicles/${vehicleId}/image/presigned-url?filename=${filename}&filetype=${filetype}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteVehicleImage = async (vehicleId) => {
  try {
    const response = await axios.delete(`${API_URL}/vehicles/${vehicleId}/image`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const uploadFileToPresignedUrl = async (file, presignedUrl, fileType) => {
  try {
    await axios.put(presignedUrl, file, {
      headers: {
        'Content-Type': fileType
      }
    });
  } catch (error) {
    throw error;
  }
};

export const confirmInvoiceUpload = async (fileInfo, invoiceId) => {
  await axios.put(`${API_URL}/invoices/${invoiceId}/manual-upload/confirm`, fileInfo, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const confirmVehicleImageUpload = async (fileInfo, vehicleId) => {
  try {
    await axios.put(`${API_URL}/vehicles/${vehicleId}/image/confirm-upload`, fileInfo, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  } catch (error) {
    throw error;
  }
};

export const getOfficalVehicleInformation = async (vehicleIdentification) => {
  try {
    return await axios.get(`${API_URL}/vehicles/${vehicleIdentification}/official-information`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  } catch (error) {
    throw error;
  }
};

export const fetchVehicleProofOfOwnershipPresignedUrl = async (filename, filetype, vehicleUid) => {
  try {
    const response = await axios.get(
      `${API_URL}/vehicles/${vehicleUid}/presigned-url?filename=${filename}&filetype=${filetype}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const confirmVehicleProofOfOwnershipUpload = async (key, vehicleUid) => {
  try {
    const response = await axios.put(`${API_URL}/vehicles/${vehicleUid}/proof-of-ownership/confirm-upload`, { key }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const finaliseVehicleProofOfOwnershipUpload = async (vehicleUid) => {
  try {
    const response = await axios.put(
      `${API_URL}/vehicles/${vehicleUid}/proof-of-ownership/upload-finalised`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchVehicleImage = async (identification) => {
  try {
    const response = await axios.get(`${API_URL}/vehicles/${identification}/image`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data.imageData;
  } catch (error) {
    throw error;
  }
};

export const fetchVehicleUserInformation = async (vehicleUid) => {
  try {
    const response = await axios.get(`${API_URL}/vehicles/${vehicleUid}/user-information`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchVehicleSpecificInformation = async (vehicleUid) => {
  try {
    const response = await axios.get(`${API_URL}/vehicles/${vehicleUid}/specific-information`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchVehicleBasicInformation = async (vehicleUid) => {
  try {
    const response = await axios.get(`${API_URL}/vehicles/${vehicleUid}/basic-information`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchVehicleHistoryFromPermission = async (vehicleUid, permissionUid) => {
  try {
    const response = await axios.get(`${API_URL}/vehicle/${vehicleUid}/history/${permissionUid}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postGranteeTemporaryPermissionToReadHistory = async (vehicleUid, recipientEmailAddress) => {
  try {
    const response = await axios.post(
      `${API_URL}/vehicles/${vehicleUid}/history/share`,
      {
        recipientEmailAddress: recipientEmailAddress
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchTransferRequest = async (vehicleUid) => {
  try {
    const response = await axios.head(`${process.env.REACT_APP_API_URL}/vehicles/${vehicleUid}/check-active-transfer`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.status;
  } catch (error) {
    throw error;
  }
};

export const fetchVehicleTransferSenderInformation = async (vehicleUid) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/vehicles/${vehicleUid}/transfer-sender-information`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const acceptVehicleTransfer = async (vehicleUid) => {
  try {
    await axios.put(
      `${process.env.REACT_APP_API_URL}/vehicles/${vehicleUid}/transfer-accept`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  } catch (error) {
    throw error;
  }
};

export const fetchSharedHistoryDetails = async (vehicleUid, permissionUid) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/vehicles/${vehicleUid}/share-history/${permissionUid}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getIdPresignedUrlOnboarding = async (filename, filetype) => {
  try {
    const response = await axios.get(
      `${API_URL}/onboarding/id/presigned-url?filename=${filename}&filetype=${filetype}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getIdPresignedUrlPostOnboarding = async (filename, filetype) => {
  try {
    const response = await axios.get(
      `${API_URL}/onboarding/id/presigned-url?filename=${filename}&filetype=${filetype}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const onboardingConfirmIdUpload = async (documentId) => {
  await axios.put(
    `${API_URL}/onboarding/id/${documentId}/confirm-upload`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

export const confirmIdUploadPostOnboarding = async (documentId) => {
  await axios.put(
    `${API_URL}/id/${documentId}/confirm-upload`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

export const getMotInformation = async (vehicleId) => {
  try {
    const response = await axios.get(`${API_URL}/vehicles/${vehicleId}/mot-information`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const getUserSubscriptionStatus = async () => {
  try {
    const response = await axios.get(`${API_URL}/subscription-status`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const getHistoryEvents = async (vehicleUid, permissionUid) => {
  try {
    const endpoint = permissionUid
      ? `${API_URL}/vehicles/${vehicleUid}/history-events/${permissionUid}`
      : `${API_URL}/vehicles/${vehicleUid}/history-events`;

    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postStep1Credentials = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/onboarding/user/step1`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postStep1ResendEmail = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/onboarding/user/step1-resend-confirmation-email`, data, {
      headers: {}
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postStep1ResendEmailLoggedIn = async () => {
  try {
    const response = await axios.post(
      `${API_URL}/step1-resend-confirmation-email`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postStep3PersonalInformation = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/onboarding/user/step3`, userData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postStep5Address = async (addressData) => {
  try {
    const response = await axios.post(`${API_URL}/onboarding/user/step5`, addressData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createCheckoutSession = async () => {
  try {
    const response = await axios.post(
      `${API_URL}/create-checkout-session`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postStripeSessionUid = async (stripeUserId) => {
  try {
    const response = await axios.post(
      `${API_URL}/subscription-confirm`,
      {
        stripeUserId: stripeUserId
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteAccount = async (password) => {
  try {
    const response = await axios.put(
      `${API_URL}/account-delete`,
      { password: password },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updatePassword = async (currentPassword, newpassword) => {
  try {
    const response = await axios.put(
      `${API_URL}/account/password`,
      {
        newPassword: newpassword,
        currentPassword: currentPassword
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAddressDetails = async (addressId) => {
  try {
    const response = await axios.get(`${API_URL}/address-details/${addressId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {}
};

export const getAddresses = async (inputValue) => {
  try {
    const response = await axios.get(`${API_URL}/addresses-autocomplete/${inputValue}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {}
};

export const getVehicleBasicInformation = async (vehicleUid) => {
  try {
    const response = await axios.get(`${API_URL}/vehicles/${vehicleUid}/basic-information`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {}
};

export const postTransferRequest = async (vehicleUid, recipientEmail, password) => {
  try {
    const response = await axios.post(
      `${API_URL}/vehicles/${vehicleUid}/send-transfer-request`,
      {
        recipientEmail: recipientEmail,
        password: password
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getActiveTransfers = async () => {
  try {
    const response = await axios.get(`${API_URL}/vehicles/transfers`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getReadPermissionsForOwner = async () => {
  try {
    const response = await axios.get(`${API_URL}/read-permissions`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const revokePermission = async (permissionId) => {
  try {
    await axios.delete(`${API_URL}/read-permissions/${permissionId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
  } catch (error) {
    throw error;
  }
};

export const getRecentNotifications = async () => {
  try {
    const response = await axios.get(`${API_URL}/notifications`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postPasswordResetQuery = async (emailAddress) => {
  try {
    const response = await axios.post(
      `${API_URL}/password-reset`,
      { emailAddress: emailAddress },
      {
        headers: {}
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postUpdatePassword = async (resetToken, newPassword) => {
  try {
    const response = await axios.post(
      `${API_URL}/password-reset/${resetToken}`,
      {
        newPassword: newPassword
      },
      {
        headers: {}
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postCustomerSupportMessage = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/support/contact`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export default {
  login,
  getUserClientOnboardingStatus,
  postUserClientOnboardingStep4,
  getCompanyGovernmentRegistrationInformation,
  postOneAppointment,
  getAllVehiclesFromUser,
  getVehicleOwnerHistory: fetchVehicleOwnerHistory,
  getAllInvoicesForUserClient,
  getUserClientInformation,
  putUserClientInformation,
  getMostRecentNotifications: getRecentNotifications,
  updateNotificationStatus,
  fetchInvoicePresignedUrl,
  uploadFileToPresignedUrl,
  confirmInvoiceUpload,
  getOfficalVehicleInformation,
  fetchVehicleProofOfOwnershipPresignedUrl,
  confirmVehicleProofOfOwnershipUpload,
  finaliseVehicleProofOfOwnershipUpload,
  fetchVehicleImage,
  fetchVehicleSpecificInformation,
  fetchVehicleHistoryFromPermission,
  fetchVehicleBasicInformation,
  postGranteeTemporaryPermissionToReadHistory,
  fetchTransferRequest,
  fetchVehicleTransferSenderInformation,
  acceptVehicleTransfer,
  fetchSharedHistoryDetails,
  getOneinvoiceForUserClient,
  getIdPresignedUrl: getIdPresignedUrlOnboarding,
  confirmIdUpload: onboardingConfirmIdUpload,
  getMotInformation,
  postStep5Address,
  getUserSubscriptionStatus,
  createCheckoutSession,
  postStripeSessionUid,
  deleteAccount,
  updatePassword,
  getHistoryEvents,
  postStep1Credentials,
  postStep1ResendEmail,
  postStep3PersonalInformation,
  getAddresses,
  getVehicleBasicInformation,
  confirmVehicleImageUpload,
  getVehicleImagePresignedUrl,
  deleteVehicleImage,
  fetchVehicleUserInformation,
  postPasswordResetQuery,
  postUpdatePassword,
  postCustomerSupportMessage,
  getReadPermissionsForOwner,
  revokePermission,
  confirmIdUploadPostOnboarding,
  getIdPresignedUrlPostOnboarding
};
