import React, { useState } from 'react';
import { Box, Button, Divider, Grid, Modal, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ManualUploadModal from '../../../Invoice/ManualUpload/ManualUploadModal';
import PrimaryButton from '../../../Global/PrimaryButton';

const VehicleActionsModal = ({ vehicleUid, identification, open, onClose, refreshHistory }) => {
  const { t } = useTranslation();
  const [isManualUploadModalOpen, setIsManualUploadModalOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='vehicle-actions-modal-title'
      aria-describedby='vehicle-actions-modal-description'
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          maxWidth: { xs: 'calc(100% - 32px)', sm: '80%', md: '60%' },
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: '0 4px 20px rgba(0,0,0,0.25)',
          p: 4,
          overflowY: 'auto'
        }}
      >
        <Typography id='vehicle-actions-modal-title' variant='h5' component='h2' textAlign='center' sx={{ mb: 3 }}>
          {t('vehicleActions.actions')}
        </Typography>
        <Divider sx={{ mb: 3 }} />
        <Grid container spacing={2} justifyContent='center'>
          <Grid item xs={12} sm={4}>
            <PrimaryButton
              text={t('vehicleActions.uploadInvoice')}
              onClick={() => setIsManualUploadModalOpen(true)}
              sx={{ py: 2, width: '100%' }}
            />
            <ManualUploadModal
              open={isManualUploadModalOpen}
              onClose={() => setIsManualUploadModalOpen(false)}
              identification={identification}
              vehicleUid={vehicleUid}
              refreshHistory={refreshHistory}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <PrimaryButton
              text={t('vehicleActions.transferToNewOwner')}
              onClick={() => navigate(`/vehicles/${vehicleUid}/transfer`)}
              sx={{ py: 2, width: '100%', backgroundColor: '#61E786' }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <PrimaryButton
              text={t('vehicleActions.shareVehicleHistory')}
              onClick={() => navigate(`/vehicles/${vehicleUid}/history/share`)}
              sx={{ py: 2, width: '100%', backgroundColor: '#14453D' }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button component={Link} to='/vehicles/remove' fullWidth variant='outlined' color='error' sx={{ py: 2 }}>
              {t('vehicleActions.removeFromAccount')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default VehicleActionsModal;
