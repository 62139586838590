import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faLocationPin, faFileInvoice, faCarAlt, faSignOutAlt, faUser, faKey } from '@fortawesome/free-solid-svg-icons';
import featureFlags from '../../featureFlags';
import './MobileMenuOverlay.css';

function MobileMenuOverlay({ onClose, isOpen }) {
  const { t } = useTranslation();
  const toggleButtonRef = useRef(null);
  const overlayRef = useRef(null);

  const menuItems = [
    {
      label: t('menu.yourVehicles'),
      icon: faCarAlt,
      path: '/'
    },
    {
      label: t('menu.invoices'),
      icon: faFileInvoice,
      path: '/invoices'
    },
    {
      label: t('menu.transfers'),
      icon: faKey,
      path: '/transfers'
    },
    {
      label: t('menu.account'),
      icon: faUser,
      path: '/account'
    },
    {
      label: t('menu.logout'),
      icon: faSignOutAlt,
      path: '/logout'
    }
  ];

  if (featureFlags.garageSearch) {
    menuItems.push({
      label: t('menu.garageFinder'),
      icon: faLocationPin,
      path: '/garage-finder'
    });
  }

  useEffect(() => {
    if (isOpen) {
      toggleButtonRef.current.classList.add('toggle-active');
      overlayRef.current.classList.add('nav-active');
    } else {
      toggleButtonRef.current.classList.remove('toggle-active');
      overlayRef.current.classList.remove('nav-active');
    }
  }, [isOpen]);

  return (
    <header className='container'>
      <div className='navbar-header'>
        <div className='toggle-button' id='toggle' ref={toggleButtonRef} onClick={onClose}></div>
      </div>
      <nav className='overlay' id='overlay' ref={overlayRef}>
        <ul>
          {menuItems.map((item) => (
            <li key={item.label} className='menu-item'>
              <Link to={item.path} onClick={onClose} className={item.label === t('menu.logout') ? 'logout-button' : ''}>
                <div className={item.label === t('menu.logout') ? 'menu-item-icon-logout' : 'menu-item-icon'}>
                  <FontAwesomeIcon icon={item.icon} />
                </div>
                <span className={item.label === t('menu.logout') ? 'menu-item-text-logout' : 'menu-item-text'}>
                  {item.label}
                </span>
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
}

export default MobileMenuOverlay;
