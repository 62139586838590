import React, { useState, useEffect, useRef } from 'react';
import AddressSearchForm from '../../../../Global/AddressSearchForm';
import AddressDetails from './AddressDetails';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateRegistrationStatus } from '../../../../../redux/authActions';
import { CircularProgress } from '@mui/material';
import ErrorFallback from '../../../../Global/ErrorFallback';
import { onboardingStatusRequest } from '../../../../../redux/Registration/onboardingStatusActions';
import { useTranslation } from 'react-i18next';
import './Step5Address.css';
import { getAddressDetails, getAddresses, postStep5Address } from '../../../../../api';
import CountrySelection from './CountrySelection';

const Step5Address = ({ handleBack }) => {
  const { t } = useTranslation();
  const userUid = useSelector((state) => state.auth.userUid);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [addressOptions, setAddressOptions] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [addressDetail, setAddressDetail] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showAddressSelection, setShowAddressSelection] = useState(false); // Flag to indicate whether to show AddressSelection

  const dropdownRef = useRef(null);

  const [postAddressLoading, setpostAddressLoading] = useState(false);
  const [postStep5Success, setPostAddressSuccess] = useState(false);
  const [postAddressError, setpostAddressError] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  const handleAddressChange = async (e) => {
    const inputValue = e.target.value;
    setSelectedAddress(inputValue);

    if (inputValue.length > 2) {
      const data = await getAddresses(inputValue);
      setAddressOptions(data);
    } else {
      setAddressOptions([]);
    }
  };

  const handleAddressOptionClick = async (selected) => {
    setSelectedAddress(selected.address);
    const data = await getAddressDetails(selected.id);
    setAddressDetail(data);
    setIsSubmitted(true);
    setIsDropdownOpen(false);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleDocumentClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropdownOpen(false);
    }
  };

  const handleGoBack = () => {
    setIsSubmitted(false);
  };

  const handleAddressSubmit = async (addressData) => {
    setpostAddressLoading(true);
    try {
      const response = await postStep5Address(addressData);

      setPostAddressSuccess(response);
      setIsSubmitted(true);

      dispatch(onboardingStatusRequest(userUid));
      dispatch(updateRegistrationStatus());
    } catch (error) {
      setpostAddressError(error);
    }
    setpostAddressLoading(false);
  };

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    setShowAddressSelection(true); // Set flag to show AddressSelection
  };

  return postAddressError ? (
    <ErrorFallback message={t('step5Address.errorOccurred')} />
  ) : (
    <div className='address-container'>
      <Header title={t('step5Address.headerTitle')} handleGoBack={handleBack} />
      {postAddressLoading ? (
        <CircularProgress />
      ) : !isSubmitted ? (
        <div className='form-container'>
          {!showAddressSelection ? (
            <CountrySelection handleCountrySelect={handleCountrySelect} />
          ) : (
            <AddressSearchForm
              inputValue={selectedAddress}
              onChange={handleAddressChange}
              onFocus={handleDropdownToggle}
              addressOptions={addressOptions}
              handleGoBackPreviousStep={handleBack}
              handleAddressOptionClick={handleAddressOptionClick}
            />
          )}
        </div>
      ) : (
        <AddressDetails
          postAddressLoading={postAddressLoading}
          postAddressError={postAddressError}
          addressDetail={addressDetail}
          handleAddressSubmit={handleAddressSubmit}
          handleGoBack={handleGoBack}
        />
      )}
    </div>
  );
};

export default Step5Address;
