import React from 'react';
import { Grid, Card, CardContent, List, ListItem, ListItemText, ListItemIcon, Divider, Skeleton } from '@mui/material';

const ComponentSkeleton = ({ count = 3, minHeight = '45vh' }) => (
  <Grid item xs={12}>
    <Card sx={{ minHeight, m: 2, boxShadow: 3 }}>
      <CardContent>
        <Skeleton variant='text' width='40%' />
        <List>
          {Array.from(new Array(count)).map((_, index) => (
            <React.Fragment key={index}>
              <ListItem>
                <ListItemIcon>
                  <Skeleton variant='circle' width={40} height={40} />
                </ListItemIcon>
                <ListItemText
                  primary={<Skeleton variant='text' width='90%' height={24} />}
                  secondary={<Skeleton variant='text' width='80%' height={20} />}
                />
              </ListItem>
              {index < count - 1 && <Divider variant='inset' component='li' />}
            </React.Fragment>
          ))}
        </List>
      </CardContent>
    </Card>
  </Grid>
);

export default ComponentSkeleton;
