import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function useResponsiveSidebar() {
  const [isSidebarHidden, setIsSidebarHidden] = useState(true); // Initially hide sidebar
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isFullyOnboarded = useSelector((state) => state.auth.isFullyOnboarded);

  useEffect(() => {
    const handleResize = () => {
      // Check window width and user authentication status to determine sidebar visibility
      if (window.innerWidth >= 992 && isLoggedIn && isFullyOnboarded) {
        setIsSidebarHidden(false); // Show sidebar
      } else {
        setIsSidebarHidden(true); // Hide sidebar
      }
    };

    // Check sidebar visibility on page load
    handleResize();

    // Add event listener for window resize to handle responsive sidebar
    window.addEventListener('resize', handleResize);

    // Cleanup: Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isLoggedIn, isFullyOnboarded]); // Update effect when authentication status changes

  return isSidebarHidden;
}

export default useResponsiveSidebar;
