import React from 'react';
import { Card, CardContent, Skeleton, Typography, Chip } from '@mui/material';

const VehicleInfoCardSkeleton = () => {
  return (
    <Card sx={{ display: 'flex', bgcolor: 'background.paper', boxShadow: 1, width: '100%' }}>
      <CardContent sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        {/* Simulate the image area */}
        <Skeleton variant='rectangular' width='30vw' height='20vh' sx={{ marginRight: 2 }} />

        {/* Simulate the content area */}
        <CardContent sx={{ flex: '1 0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography variant='h5' component='div'>
            <Skeleton width='80%' />
          </Typography>
          <Skeleton variant='text' width='60%' sx={{ marginBottom: 1 }} />
          <Skeleton variant='text' width='40%' />

          {/* Simulate a registration plate */}
          <Skeleton variant='rectangular' width={160} height={30} sx={{ mt: 1, mb: 1 }} />

          {/* Simulate chip for active transfer */}
          <Chip
            component={Skeleton}
            sx={{
              width: 100,
              height: 30,
              borderRadius: '5px',
              backgroundColor: '#E0E0E0' // Light grey to mimic disabled state
            }}
          />
        </CardContent>
      </CardContent>
    </Card>
  );
};

export default VehicleInfoCardSkeleton;
