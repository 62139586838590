import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Skeleton from '@mui/material/Skeleton';
import RegistrationPlate from '../../Global/RegistrationPlate';
import { useTranslation } from 'react-i18next';
import './TransferVehicleForm.css';
import PrimaryButton from '../../Global/PrimaryButton';
import { fetchVehicleImage, getVehicleBasicInformation, postTransferRequest } from '../../../api';

const TransferVehicleForm = () => {
  const { vehicleUid } = useParams();
  const [open, setOpen] = useState(false);
  const [recipientEmail, setRecipientEmail] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [vehicleInfo, setVehicleInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageData, setImageData] = useState('');

  const [postTransferVehicle, setPostTransferVehicle] = useState(null);
  const [postTransferVehicleError, setPostTransferVehicleError] = useState(null);
  const [postTransferVehicleLoading, setPostTransferVehicleLoading] = useState(false);

  useEffect(() => {
    fetchVehicleInfo(vehicleUid)
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [vehicleUid]);

  const fetchVehicleInfo = async (vehicleUid) => {
    try {
      const vehicleInfo = await getVehicleBasicInformation(vehicleUid);
      setVehicleInfo(vehicleInfo);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const fetchImage = async (vehicleIdentification) => {
      const imageDataBase64 = await fetchVehicleImage(vehicleIdentification);

      setImageData(imageDataBase64);

      setImageLoaded(true); // Set imageLoaded to true after fetching, regardless of success or failure
    };
    if (vehicleInfo && vehicleInfo.identification) {
      fetchImage(vehicleInfo.identification);
    }
  }, [vehicleInfo]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const recipientEmail = formData.get('recipientEmail').trim().toLowerCase();
    setRecipientEmail(recipientEmail);
    setOpen(true);
  };

  const handlePasswordSubmit = async (password) => {
    setPostTransferVehicleLoading(true);

    try {
      const response = await postTransferRequest(vehicleUid, recipientEmail, password);

      setPostTransferVehicle(response);

      navigate('/');
    } catch (error) {
      setPostTransferVehicleError(error);
    }
    setPostTransferVehicleLoading(false);

    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '5vh'
      }}
    >
      <Paper elevation={3} sx={{ padding: '20px', width: { xs: '90vw', sm: '50vw' } }}>
        <Typography variant='h4' align='center' gutterBottom>
          {t('transferVehicleForm.pageTitle')}
          <Tooltip title={t('transferVehicleForm.tooltip')}>
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Typography>
        {loading ? (
          <Box>
            <Skeleton variant='rectangular' width='100%' height={200} sx={{ mb: 2 }} />
            <Skeleton variant='text' width='60%' sx={{ mx: 'auto', mb: 1 }} />
            <Skeleton variant='text' width='50%' sx={{ mx: 'auto', mb: 1 }} />
            <Skeleton variant='text' width='40%' sx={{ mx: 'auto', mb: 1 }} />
            <Skeleton variant='rectangular' width='80%' height={56} sx={{ mx: 'auto', mb: 2 }} />{' '}
            {/* For the text field */}
            <Skeleton variant='rectangular' width='80%' height={36} sx={{ mx: 'auto' }} /> {/* For the button */}
          </Box>
        ) : (
          <Card style={{ marginBottom: '20px' }}>
            <CardContent>
              <Typography
                variant='h5'
                align='center'
              >{`${vehicleInfo.make} ${vehicleInfo.model} ${vehicleInfo.trim}, ${vehicleInfo.yearOfManufacture}`}</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                  {imageLoaded ? (
                    <Grid item xs={12}>
                      <img
                        src={`data:image/jpeg;base64,${imageData}`}
                        alt='vehicle'
                        style={{
                          width: '100%',
                          maxWidth: '100%',
                          display: 'block',
                          marginLeft: 'auto',
                          marginRight: 'auto'
                        }}
                      />
                    </Grid>
                  ) : (
                    <Skeleton variant='rectangular' width={300} height={150} />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body1' align='center'>{`${t('transferVehicleForm.vehicleClass')}: ${
                    vehicleInfo.vehicleClass
                  }`}</Typography>
                  <Typography variant='body1' align='center'>{`${t('transferVehicleForm.fuelType')}: ${
                    vehicleInfo.fuelType
                  }`}</Typography>
                  <Grid container alignItems='center' spacing={1} justifyContent='center'>
                    <Grid item>
                      <Typography variant='body1'>{`${t('transferVehicleForm.color')}: ${
                        vehicleInfo.color
                      }`}</Typography>
                    </Grid>
                    <Grid item>
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: vehicleInfo.color.toLowerCase(),
                          border: '1px solid #ccc'
                        }}
                      />
                    </Grid>
                  </Grid>
                  <RegistrationPlate identification={vehicleInfo.identification} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
        <div>
          <form onSubmit={handleFormSubmit}>
            <TextField
              id='recipientEmail'
              name='recipientEmail'
              label={t('transferVehicleForm.recipientEmail')}
              fullWidth
              required
            />
            <PrimaryButton
              text={t('transferVehicleForm.transferButton')}
              type='submit'
              sx={{ marginTop: '1vh', width: '100%' }}
            />
          </form>
        </div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{t('transferVehicleForm.dialogTitle')}</DialogTitle>
          <DialogContent>
            <TextField autoFocus margin='dense' id='password' label='Password' type='password' fullWidth />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t('transferVehicleForm.cancelButton')}</Button>
            <Button
              onClick={() => handlePasswordSubmit(document.getElementById('password').value)}
              disabled={postTransferVehicleLoading}
            >
              {t('transferVehicleForm.confirmButton')}
            </Button>
          </DialogActions>
        </Dialog>

        {postTransferVehicle && (
          <Dialog open={true}>
            <DialogTitle>{t('transferVehicleForm.successDialogTitle')}</DialogTitle>
            <DialogContent>
              <Typography variant='body1'>
                {t('transferVehicleForm.successDialogMessage', {
                  recipientEmail
                })}
              </Typography>
            </DialogContent>
            <DialogActions>
              <PrimaryButton onClick={() => setPostTransferVehicle(null)}>{t('transferVehicleForm.ok')}</PrimaryButton>
            </DialogActions>
          </Dialog>
        )}

        {postTransferVehicleError && (
          <Dialog open={true}>
            <DialogTitle>{t('transferVehicleForm.errorDialogTitle')}</DialogTitle>
            <DialogContent>
              <Typography variant='body1'>{t('transferVehicleForm.errorDialogMessage')}</Typography>
            </DialogContent>
            <DialogActions>
              <PrimaryButton onClick={() => setPostTransferVehicleError(null)}>
                {t('transferVehicleForm.ok')}
              </PrimaryButton>
            </DialogActions>
          </Dialog>
        )}
      </Paper>
    </Container>
  );
};

export default TransferVehicleForm;
