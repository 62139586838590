import React from 'react';
import { Button } from '@mui/material';

const SecondaryButton = ({
  onClick,
  disabled,
  text,
  icon,
  id,
  isFileInput,
  onFileChange,
  inputAccept,
  inputKey,
  sx,
  type = 'button'
}) => {
  if (isFileInput) {
    return (
      <Button
        id={id}
        component='label' // Makes the button act as a label for the hidden file input
        variant='outlined'
        disabled={disabled}
        startIcon={icon}
        type={type}
        sx={{
          borderColor: 'var(--color-main)',
          color: 'var(--color-main)',
          padding: '10px 24px',
          '&:hover': {
            borderColor: 'var(--color-secondary)',
            backgroundColor: 'var(--color-secondary-opacity)'
          },
          ...sx // Merge with provided sx styles
        }}
      >
        {text}
        <input type='file' hidden onChange={onFileChange} accept={inputAccept} key={inputKey} />
      </Button>
    );
  }

  return (
    <Button
      id={id}
      variant='outlined'
      onClick={onClick}
      disabled={disabled}
      startIcon={icon}
      type={type}
      sx={{
        borderColor: 'var(--color-main)',
        color: 'var(--color-main)',
        marginRight: '1vw',
        padding: '10px 24px',
        '&:hover': {
          borderColor: 'var(--color-secondary)'
        },
        ...sx // Merge with provided sx styles
      }}
    >
      {text}
    </Button>
  );
};

export default SecondaryButton;
