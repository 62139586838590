import React, { useState } from 'react';
import { Typography, Box, Grid } from '@mui/material';
import DisplayS3Object from '../../../Global/S3Object/DisplayS3Object';
import { useTranslation } from 'react-i18next';
import SecondaryButton from '../../../Global/SecondaryButton';

const InvoiceEventItem = ({ item }) => {
  const { eventSubType, invoiceDate, invoiceId, error: initialError } = item;
  const { t } = useTranslation();
  const [error, setError] = useState(initialError);

  // Format the invoice date
  const formattedInvoiceDate = new Date(invoiceDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  // Handle loading error from DisplayS3Object
  const handleLoadingError = (errorMessage) => {
    setError(errorMessage); // Update the local state to reflect the error
  };

  return (
    <Box sx={{ minWidth: 275, mt: 2, p: 2, boxShadow: 'none', borderRadius: '4px', position: 'relative' }}>
      <Grid container justifyContent='space-between' alignItems='flex-start'>
        <Grid item>
          <Typography variant='h6' color='primary' gutterBottom>
            {eventSubType === 'MANUAL_INVOICE_UPLOAD'
              ? t('invoiceEventItem.invoiceUploaded')
              : t('invoiceEventItem.invoice')}
          </Typography>
        </Grid>
      </Grid>

      {error ? (
        <>
          <Typography variant='body2' color='error' sx={{ mt: 2 }}>
            {t('invoiceEventItem.error')}
          </Typography>
          <SecondaryButton text={formattedInvoiceDate} disabled={true} />
        </>
      ) : (
        <DisplayS3Object
          invoiceId={invoiceId}
          fulltext={false}
          formattedInvoiceDate={formattedInvoiceDate}
          errorLoadingInvoice={handleLoadingError}
        />
      )}
    </Box>
  );
};

export default InvoiceEventItem;
