import { combineReducers } from 'redux';

import { AUTH_LOGIN, AUTH_LOGOUT, POST_USER_CLIENT_REGISTRATION_SUCCESS } from './actionTypes';
import onboardingStatusReducer from './Registration/onboardingStatusReducer';
import vehicleReducer from './Vehicle/vehicleReducer';
import invoiceReducer from './Invoice/invoiceReducer';
import { clearCookies } from '../utils/clearCookies';

const initialState = {
  auth: {
    isLoggedIn: null,
    isFullyOnboarded: null,
    userUid: null,
    userEmail: null,
    timezone: null
  }
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_USER_CLIENT_REGISTRATION_SUCCESS:
      return {
        ...state,
        isFullyOnboarded: true
      };

    case AUTH_LOGIN:
      return {
        ...state,
        auth: {
          isLoggedIn: true,
          isFullyOnboarded: action.status === 'true',
          userUid: action.userUid,
          userEmail: action.userEmail,
          timezone: action.timezone
        }
      };

    case AUTH_LOGOUT:
      clearCookies();

      return {
        ...state,
        auth: {
          isLoggedIn: false,
          isFullyOnboarded: false,
          userUid: null,
          userEmail: null,
          timezone: null
        }
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  auth: authReducer,
  onboardingStatus: onboardingStatusReducer,
  vehicles: vehicleReducer,
  invoices: invoiceReducer
});

export default rootReducer;
