import React from 'react';

const RegistrationPlate = ({ identification }) => (
  <div
    style={{
      backgroundColor: '#00247D',
      border: '1px solid black',
      borderRadius: '5px',
      color: 'black',
      fontFamily: 'sans-serif',
      maxWidth: 'fit-content',
      margin: '0 auto',
      display: 'flex',
      overflow: 'hidden'
    }}
  >
    <div
      style={{
        backgroundColor: '#00247D',
        color: 'white',
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRight: '2px solid white'
      }}
    >
      <img src={process.env.PUBLIC_URL + '/uk-flag.png'} alt='UK Flag' style={{ height: '20px', width: 'auto' }} />
    </div>
    <div
      style={{
        backgroundColor: '#FCD116',
        padding: '10px',
        fontWeight: 'bold'
      }}
    >
      {identification}
    </div>
  </div>
);

export default RegistrationPlate;
