import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../Global/PrimaryButton';

const NoVehicle = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleAddVehicle = () => {
    navigate('/vehicles/add');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '70vh',
        padding: '2rem',
        backgroundColor: '#f5f5f5',
        borderRadius: '15px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        maxWidth: '600px',
        margin: 'auto',
        marginTop: '10vh'
      }}
    >
      <Typography variant='h4' align='center' mb={2} sx={{ fontWeight: 'bold' }}>
        {t('vehicleList.noVehiclesMainText')}
      </Typography>
      <Typography variant='body1' align='center' mb={4}>
        {t('vehicleList.noVehiclesSubText')}
      </Typography>
      <PrimaryButton onClick={handleAddVehicle} text={t('vehicleList.registerVehicle')} />
    </Box>
  );
};

export default NoVehicle;
