import React, { useState } from 'react';
import {
  Button,
  Container,
  FormControl,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteAccountForm from './DeleteAccountForm';
import { updatePassword } from '../../api';

function AccountSecurityForm() {
  const { t } = useTranslation();
  const [passwordUpdating, setPasswordUpdating] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordDontMatchError, setPasswordDontMatchError] = useState(false);
  const [successMessageOpen, setSuccessMessageOpen] = useState(false);

  const handleUpdatePassword = async () => {
    if (newPassword !== confirmNewPassword) {
      setPasswordDontMatchError(true);
      setPasswordError(false);
      return;
    }

    setPasswordDontMatchError(false);
    try {
      setPasswordUpdating(true);
      await updatePassword(currentPassword, newPassword);

      // Reset form fields on success
      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
      setPasswordError(false);

      // Show success banner
      setSuccessMessageOpen(true);
    } catch (error) {
      setPasswordError(true); // Handle other errors related to password update
    } finally {
      setPasswordUpdating(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSuccessMessageOpen(false);
  };

  return (
    <Container maxWidth='sm'>
      <Paper sx={{ p: 4, mt: 3, mb: 3, bgcolor: 'background.paper' }}>
        <Typography variant='h6' gutterBottom>
          {t('changePasswordForm.changePassword')}
        </Typography>
        <FormControl fullWidth margin='normal'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label={t('changePasswordForm.currentPassword')}
                type='password'
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('changePasswordForm.newPassword')}
                type='password'
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('changePasswordForm.confirmNewPassword')}
                type='password'
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                fullWidth
                error={passwordDontMatchError || passwordError}
                helperText={
                  (passwordError && t('changePasswordForm.errorMessage')) ||
                  (passwordDontMatchError && t('changePasswordForm.passwordsDoNotMatch'))
                }
              />
            </Grid>
          </Grid>
          <Stack direction='row' spacing={2} justifyContent='center' sx={{ mt: 2 }}>
            {passwordUpdating ? (
              <CircularProgress />
            ) : (
              <Button
                variant='contained'
                onClick={handleUpdatePassword}
                color='primary'
                sx={{
                  backgroundColor: 'var(--color-main)',
                  '&:hover': {
                    backgroundColor: 'var(--color-secondary)'
                  }
                }}
              >
                {t('changePasswordForm.updatePassword')}
              </Button>
            )}
          </Stack>
        </FormControl>

        <DeleteAccountForm />

        {/* Success Snackbar */}
        <Snackbar
          open={successMessageOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity='success' sx={{ width: '100%' }}>
            {t('changePasswordForm.successMessage')}
          </Alert>
        </Snackbar>
      </Paper>
    </Container>
  );
}

export default AccountSecurityForm;
