import React from 'react';
import { Button } from '@mui/material';

const PrimaryButton = ({
  onClick,
  id,
  disabled,
  text,
  icon,
  isFileInput,
  onFileChange,
  inputAccept,
  inputKey,
  sx,
  type = 'button'
}) => {
  if (isFileInput) {
    return (
      <Button
        id={id}
        component='label'
        variant='contained'
        disabled={disabled}
        type={type}
        sx={{
          backgroundColor: 'var(--color-main)',
          padding: '10px 24px',
          '&:hover': {
            backgroundColor: 'var(--color-secondary)'
          },
          ...sx
        }}
      >
        {icon}
        {text}
        <input type='file' hidden onChange={onFileChange} accept={inputAccept} key={inputKey} />
      </Button>
    );
  }

  return (
    <Button
      id={id}
      variant='contained'
      onClick={onClick}
      disabled={disabled}
      startIcon={icon}
      type={type}
      sx={{
        backgroundColor: 'var(--color-main)',
        padding: '10px 24px',
        '&:hover': {
          backgroundColor: 'var(--color-secondary)'
        },
        ...sx
      }}
    >
      {text}
    </Button>
  );
};

export default PrimaryButton;
