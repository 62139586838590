import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import UpdateClientInfoForm from './UpdateInfo/UpdateClientInfoForm';
import { Box, Container, Grid, Tab, Tabs } from '@mui/material';
import ClientDetails from './ClientDetails';
import AccountSecurityForm from './AccountSecurityForm';
import ContactSupportForm from './ContactSupportForm';

function AccountPage() {
  const { t } = useTranslation();
  const [selectedMenuItem, setSelectedMenuItem] = useState('profile');
  const [isEditing, setIsEditing] = useState(false);

  const handleMenuItemClick = (event, id) => {
    setSelectedMenuItem(id);
  };

  const renderContent = () => {
    switch (selectedMenuItem) {
      case 'profile':
        return isEditing ? (
          <>
            <UpdateClientInfoForm onCancel={() => setIsEditing(false)} />
          </>
        ) : (
          <>
            <ClientDetails onEdit={() => setIsEditing(true)} />
          </>
        );
      case 'security':
        return <AccountSecurityForm />;
      case 'support':
        return <ContactSupportForm />;
      default:
        return null;
    }
  };

  return (
    <Box mt={2}>
      <Container maxWidth='md'>
        <Grid container>
          <Grid item xs={12}>
            <Tabs
              value={selectedMenuItem}
              onChange={handleMenuItemClick}
              centered
              sx={{
                '.MuiTab-textColorInherit.Mui-selected': {
                  color: 'var(--color-main)'
                },
                '.MuiTab-textColorInherit': {
                  color: 'var(--color-main)'
                },
                '.MuiTabs-indicator': {
                  backgroundColor: 'var(--color-main)'
                }
              }}
            >
              <Tab
                label={t('accountPage.profile')}
                value='profile'
                sx={{
                  color: 'var(--color-main)',
                  '&.Mui-selected': {
                    color: 'var(--color-main-selected)'
                  },
                  '&.Mui-focusVisible': {
                    backgroundColor: 'var(--color-main-focus)'
                  }
                }}
              />
              <Tab
                label={t('accountPage.security')}
                value='security'
                sx={{
                  color: 'var(--color-main)',
                  '&.Mui-selected': {
                    color: 'var(--color-main-selected)'
                  },
                  '&.Mui-focusVisible': {
                    backgroundColor: 'var(--color-main-focus)'
                  }
                }}
              />
              <Tab
                label={t('accountPage.support')}
                value='support'
                sx={{
                  color: 'var(--color-main)',
                  '&.Mui-selected': {
                    color: 'var(--color-main-selected)'
                  },
                  '&.Mui-focusVisible': {
                    backgroundColor: 'var(--color-main-focus)'
                  }
                }}
              />
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            {renderContent()}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default AccountPage;
