import React from 'react';
import { Grid, TextField, Box } from '@mui/material';

const PersonalInfo = ({ formFields, handleChange }) => {
  return (
    <>
      <Box mb={2}>
        {' '}
        {/* Add margin-bottom to the first field */}
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            label='First Name'
            value={formFields.firstName}
            onChange={handleChange('firstName')}
            variant='outlined'
          />
        </Grid>
      </Box>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          label='Last Name'
          value={formFields.lastName}
          onChange={handleChange('lastName')}
          variant='outlined'
        />
      </Grid>
    </>
  );
};

export default PersonalInfo;
