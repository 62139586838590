import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import ColorLensIcon from '@mui/icons-material/ColorLens';

const GlobalDetails = ({ type, yearOfManufacture, color, fuelType }) => {
  const InfoWithIcon = ({ icon, children }) => (
    <Box display='flex' alignItems='center' my={1}>
      {icon}
      <Typography variant='body2' ml={1} component='span'>
        {children}
      </Typography>
    </Box>
  );

  return (
    <Grid container spacing={2} marginLeft={1} marginTop={2}>
      <Grid item xs={6} sm={3}>
        <InfoWithIcon icon={<DriveEtaIcon color='action' />} description='Type'>
          {type}
        </InfoWithIcon>
      </Grid>
      <Grid item xs={6} sm={3}>
        <InfoWithIcon icon={<CalendarTodayIcon color='action' />} description='Year'>
          {yearOfManufacture}
        </InfoWithIcon>
      </Grid>
      <Grid item xs={6} sm={3} display='flex' justifyContent='flex-start' alignItems='center'>
        <InfoWithIcon icon={<ColorLensIcon color='action' />} description='Color'>
          <Box
            sx={{
              width: 20,
              height: 20,
              backgroundColor: color.toLowerCase(),
              border: '1px solid #ccc',
              display: 'inline-block',
              marginLeft: '8px'
            }}
          />
        </InfoWithIcon>
      </Grid>
      <Grid item xs={6} sm={3}>
        <InfoWithIcon icon={<LocalGasStationIcon color='action' />} description='Fuel'>
          {fuelType}
        </InfoWithIcon>
      </Grid>
    </Grid>
  );
};

export default GlobalDetails;
