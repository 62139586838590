import React, { useEffect, useState } from 'react';
import { fetchVehicleImage, getActiveTransfers, getReadPermissionsForOwner } from '../../../api';
import { Box, Tab, Tabs } from '@mui/material';
import NoTransfer from './NoTransfer';
import TransferCard from './TransferCard';
import TransferCardSkeleton from './TransferCardSkeleton';
import ReadPermissionCard from './ReadPermissions/ReadPermissionCard';
import ErrorFallback from '../../Global/ErrorFallback';
import { useTranslation } from 'react-i18next';

const TransfersList = () => {
  const { t } = useTranslation();
  const [activeTransfers, setActiveTransfers] = useState([]);
  const [pastTransfers, setPastTransfers] = useState([]);
  const [readPermissions, setReadPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  const renderReadPermissions = () => {
    if (loading) return <TransferCardSkeleton count={3} />;
    if (!readPermissions.length) return <NoTransfer type='permission' />;
    return (
      <Box sx={{ mt: '5vh' }}>
        {readPermissions.map((permission) => (
          <ReadPermissionCard
            key={permission.id}
            permission={permission}
            granteeEmailAddress={permission['0']?.granteeEmailAddress || permission.grantee?.granteeEmailAddress}
          />
        ))}
      </Box>
    );
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const [activeResponse, permissionsResponse] = await Promise.all([
          getActiveTransfers(),
          getReadPermissionsForOwner()
        ]);

        const { activeTransfers, pastTransfers } = activeResponse;
        const allTransfers = [...activeTransfers, ...pastTransfers];
        const transfersWithImages = await Promise.all(
          allTransfers.map(async (transfer) => {
            try {
              const imageDataBase64 = await fetchVehicleImage(transfer.vehicle.identification);
              return { ...transfer, imageData: imageDataBase64 };
            } catch (imageError) {
              console.error('Failed to fetch image for vehicle:', imageError);
              return { ...transfer, imageData: null };
            }
          })
        );

        setActiveTransfers(transfersWithImages.filter((t) => !t.completedAt));
        setPastTransfers(transfersWithImages.filter((t) => t.completedAt));
        setReadPermissions(permissionsResponse);
      } catch (err) {
        setError(err.message);
        console.error('Failed to fetch data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (error) {
    return (
      <Box display='flex' alignItems='center' justifyContent='center' height='70vh'>
        <ErrorFallback message={t('userInfo.errorFallback')} />
      </Box>
    );
  }

  return (
    <Box sx={{ margin: 2 }}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        variant='scrollable'
        scrollButtons='auto'
        allowScrollButtonsMobile
      >
        <Tab label='Active Transfers' />
        <Tab label='Past Transfers' />
        <Tab label='Read Permissions' />
      </Tabs>
      {tabValue === 0 ? (
        loading ? (
          <TransferCardSkeleton count={3} />
        ) : activeTransfers.length ? (
          <Box sx={{ mt: '5vh' }}>
            {activeTransfers.map((transfer) => (
              <TransferCard key={transfer.id} transfer={transfer} status='active' />
            ))}
          </Box>
        ) : (
          <NoTransfer type='active' />
        )
      ) : tabValue === 1 ? (
        loading ? (
          <TransferCardSkeleton count={3} />
        ) : pastTransfers.length ? (
          <Box sx={{ mt: '5vh' }}>
            {pastTransfers.map((transfer) => (
              <TransferCard key={transfer.id} transfer={transfer} status='past' />
            ))}
          </Box>
        ) : (
          <NoTransfer type='past' />
        )
      ) : (
        renderReadPermissions()
      )}
    </Box>
  );
};

export default TransfersList;
