import React from 'react';
import { useSelector } from 'react-redux';
import MobileVehicleCard from './MobileVehicleCard';
import { Box, Grid, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

const MobileSmallVehicleCountLayout = () => {
  const userVehicles = useSelector((state) => state.vehicles.userVehicles);
  const navigate = useNavigate();

  const handleAddVehicleClick = () => {
    navigate('/vehicles/add');
  };

  return (
    <Box sx={{ maxHeight: '90vh', overflow: 'auto' }}>
      <Grid container spacing={4}>
        {userVehicles.slice(0, 4).map((vehicle) => (
          <Grid item xs={12} sm={6} md={3} key={vehicle.id}>
            <MobileVehicleCard vehicle={vehicle} />
          </Grid>
        ))}
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px'
          }}
        >
          <IconButton
            color='primary'
            onClick={handleAddVehicleClick}
            size='large'
            sx={{
              color: 'white',
              fontSize: '8rem',
              backgroundColor: 'var(--color-main)',
              width: '4rem',
              height: '4rem',
              '&:hover': {
                backgroundColor: 'var(--color-secondary)'
              }
            }}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MobileSmallVehicleCountLayout;
