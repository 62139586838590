import React, { useState } from 'react';
import { Box, CircularProgress, IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SecondaryButton from '../SecondaryButton';
import { getOneinvoiceForUserClient } from '../../../api';
import PDFViewer from './PdfViewer';
import ImageViewer from './ImageViewer';
import { useTranslation } from 'react-i18next';

function DisplayS3Object({ invoiceId, errorLoadingInvoice, formattedInvoiceDate }) {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);
  const [invoiceFileType, setInvoiceFileType] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [rotationAngle, setRotationAngle] = useState(0);

  const handleOpenModal = async () => {
    setLoading(true);
    try {
      const response = await getOneinvoiceForUserClient(invoiceId);
      setInvoiceData(response.invoice);
      setInvoiceFileType(response.fileType || '');
      setOpenModal(true);
    } catch (error) {
      setError(error.message || 'Failed to load the document');
      errorLoadingInvoice && errorLoadingInvoice(error.message || 'Failed to load the document');
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => setOpenModal(false);
  const handleRotate = () => setRotationAngle((prev) => (prev + 90) % 360);

  const renderContent = () => {
    if (loading) return <CircularProgress />;

    const fileData = `data:${invoiceFileType};base64,${invoiceData}`;
    if (invoiceFileType.includes('pdf')) {
      return <PDFViewer fileData={fileData} numPages={numPages} onLoadSuccess={setNumPages} />;
    } else if (invoiceFileType.includes('jpeg') || invoiceFileType.includes('png')) {
      return <ImageViewer imageData={fileData} rotationAngle={rotationAngle} onRotate={handleRotate} />;
    } else {
      return 'Unsupported file type.';
    }
  };

  return (
    <>
      <SecondaryButton
        text={formattedInvoiceDate ? formattedInvoiceDate : t('displayS3Object.showFile')}
        onClick={handleOpenModal}
        disabled={loading || error}
        icon={loading ? <CircularProgress size={24} /> : null}
      />
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            overflowY: 'auto'
          }}
        >
          <IconButton
            onClick={handleCloseModal}
            sx={{ position: 'fixed', right: '10px', top: '10px', color: 'gray', zIndex: 1300 }}
          >
            <CloseIcon />
          </IconButton>
          {renderContent()}
        </Box>
      </Modal>
    </>
  );
}

export default DisplayS3Object;
