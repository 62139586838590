import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postVehicleInformation } from '../../../../api';
import { CircularProgress, Grid, Paper, Typography } from '@mui/material';
import ErrorFallback from '../../../Global/ErrorFallback';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import VehicleInfo from './VehicleInfo';
import ActionButtons from './ActionButtons';
import VehicleImage from './VehicleImage';

const ConfirmVehiclePage = ({ setStep, vehicle, onBack, setNewVehicleId }) => {
  const { t } = useTranslation();
  const [postNewVehicleLoading, setPostNewVehicleLoading] = useState(false);
  const [postNewVehicleError, setPostNewVehicleError] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleConfirmVehicleData = async (e) => {
    e.preventDefault();
    setPostNewVehicleLoading(true);
    try {
      const vehicleId = await postVehicleInformation(vehicle.vehicleIdentification);
      setNewVehicleId(vehicleId);
      setStep(3);
    } catch (error) {
      setPostNewVehicleError(error);
    } finally {
      setPostNewVehicleLoading(false);
    }
  };

  let errorMessage;
  let instructions;

  if (postNewVehicleError?.response?.status === 403) {
    errorMessage = t('addVehicleForm.error.vehicleRegistered');
    instructions = t('addVehicleForm.error.vehicleRegisteredInstructions');
  } else if (postNewVehicleError?.response?.status === 409) {
    errorMessage = t('addVehicleForm.error.vehicleAlreadyRegistered');
    instructions = t('addVehicleForm.error.vehicleAlreadyRegisteredInstructions');
  } else if (postNewVehicleError) {
    errorMessage = t('addVehicleForm.error.unexpected');
    instructions = t('addVehicleForm.error.unexpectedInstructions');
  }

  return postNewVehicleLoading ? (
    <CircularProgress />
  ) : postNewVehicleError ? (
    <ErrorFallback message={errorMessage} instructions={instructions} />
  ) : (
    <Grid container spacing={3} style={{ width: isSmallScreen ? '90vw' : '50vw' }}>
      <Grid item xs={12}>
        <Paper
          className='add-vehicle-card'
          style={{ border: '1px solid #000', padding: isSmallScreen ? '8px' : '16px' }}
        >
          <Grid container spacing={2} direction='column' alignItems='center'>
            <Grid item xs={12}>
              <h2>
                {vehicle.make} {vehicle.model} {vehicle.trim}
              </h2>
            </Grid>
            <VehicleImage vehicleIdentification={vehicle.vehicleIdentification} />
            <Typography variant='body2' color='textSecondary' sx={{ mt: 1, mb: 2 }} align='center'>
              {t('addVehicleForm.notice.customizeLater')}
            </Typography>
            <VehicleInfo vehicle={vehicle} />
            <ActionButtons onBack={onBack} onConfirm={handleConfirmVehicleData} />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ConfirmVehiclePage;
