import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './LoginButton.css';
import { useTranslation } from 'react-i18next';

const LoginButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/login');
  };

  return (
    <>
      <Button variant='contained' size='small' className='login-button' onClick={handleButtonClick}>
        {t('loginButton.login')}
      </Button>
    </>
  );
};

export default LoginButton;
